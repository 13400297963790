<style scoped>
  .card-header .blink-danger{
    -webkit-animation: testing 2s infinite;  /* Safari 4+ */
    -moz-animation: testing 2s infinite;  /* Fx 5+ */
    -o-animation: testing 2s infinite;  /* Opera 12+ */
    animation: testing 2s infinite;  /* IE 10+, Fx 29+ */
  }
  .card-footer{
    font-weight: bold;
  }

  .installment-terms-cards .card p{
    font-size:calc(9px + 0.3vw);
    color: grey;
    margin-bottom: 0.5rem;
  }
  .installment-terms-cards .card .highlight-amount{
    font-size:calc(10px + 0.5vw);
    color: black;
  }

  .blink-danger{
    -webkit-animation: DANGER-BLINKING 2s infinite;  /* Safari 4+ */
    -moz-animation: DANGER-BLINKING 2s infinite;  /* Fx 5+ */
    -o-animation: DANGER-BLINKING 2s infinite;  /* Opera 12+ */
    animation: DANGER-BLINKING 2s infinite;  /* IE 10+, Fx 29+ */
  }
  @-webkit-keyframes DANGER-BLINKING {
    50% { color: red; }
  }

  @-webkit-keyframes testing {
    50% { 
      transform: scale(1.15);
      font-weight: bolder;
    }
  }

  .summary, .installment-terms-cards{
    font-size:calc(10px + 0.3vw);
    max-height: 450px;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  .payment-history table tr th, .payment-history table tr td{
    font-size:calc(10px + 0.3vw);
    padding: 10px 0px;
    line-height: 20px;
  }

  @media only screen and (max-width: 700px) {
    .payment-history table tr th, .payment-history table tr td{
      font-size:calc(8px + 0.1vw);
      padding: 5px 0px;
      line-height: 10px;
    }
  }

  .w-45{
    width: 48% !important;
  }

  .schedule-table{
    display:flex; 
    justify-content:space-between;
  }

  .schedule-table-hr{
    margin: 0.5rem 0;
    color: black;
    height: 1.5px;
  }

  #table_installment .card-footer {
    padding: 0.25rem 1.25rem;
    background-color: rgba(0, 0, 0, 0.1);
    border-top: 0 solid #f6f6f6;
  }

  .text-muted{
    --bs-text-opacity: 0.6;
    color: #A4A4A4 !important;
  }

</style>
<template>
  <div>
    <div class="custom-accordion">
      <b-card class="mt-4">
        <h6 class="mb-2">
          <a
            href="javascript: void(0);"
            class="text-dark"
            data-toggle="collapse"
            v-b-toggle.installment_collapse>
            <i class="mdi mdi-chevron-up float-end accor-down-icon"></i>Installment
            </a>
            <div class="float-end me-1" v-show="loan_id !== '0' && compute_user.permissions.includes('*')">
              <button class="btn btn-danger btn-sm" v-show="!flag_edit_installment && loan_id !== '0' && installment.status !== 'COMPLETE'"  @click="cancel">
                Cancel
              </button>
  
              <button class="btn btn-info btn-sm" :disabled="flag_save_installment" v-show="flag_edit_installment && loan_id !== '0' "  @click="save">
                Save
              </button>
            </div>
        </h6>

        <b-collapse id="installment_collapse" visible class="mt-1">
          
          <div class="row mb-2">
            <div class="col-md-2">
              <b-form-group
                label="Type / 类型"
                label-for="form-loan-type"
                class="mb-3"
              >
                <b-form-checkbox
                  :disabled="!flag_edit_installment && loan_id !== '0' "
                  id="form-loan-type"
                  value="PP"
                  unchecked-value="REGULAR"
                  v-model="installment.type"
                  plain
                  >PP</b-form-checkbox
                >
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group
                label="Pay / 供"
                label-for="form-loan-installment-amount"
                class="mb-3"
              >
                <b-form-input
                  id="form-loan-installment-amount"
                  type="number"
                  :disabled=" !flag_edit_installment && loan_id !== '0' "
                  @change="generateInstallmentSchedule"
                  v-model="installment.pay_per_term"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-2" v-show="installment.type !== 'PP' ">
              <b-form-group
                label="Term / 期"
                label-for="form-loan-installment-term"
                class="mb-3"
              >
                <b-form-input
                  id="form-loan-installment-term"
                  type="number"
                  :disabled=" !flag_edit_installment && loan_id !== '0' "
                  @change="generateInstallmentSchedule"
                  v-model="installment.term"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-2">
              <b-form-group
                label="Days / 日"
                label-for="form-loan-installment-days"
                class="mb-3"
              >
                <b-form-input
                  id="form-loan-installment-days"
                  type="number"
                  :disabled=" !flag_edit_installment && loan_id !== '0' "
                  @change="generateInstallmentSchedule"
                  v-model="installment.day_term"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group
                label="Start Date / 开始日期"
                label-for="form-loan-installment-date"
                class="mb-3"
              >
                <date-picker :disabled=" !flag_edit_installment && loan_id !== '0' " id="form-loan-installment-date" @change="generateInstallmentSchedule" v-model="installment_start_date" @input="installment.start_date = fixDate($event)" :first-day-of-week="1" lang="en" format="YYYY-MM-DD"></date-picker>
              </b-form-group>
            </div>
          </div>

          <b-tabs card>
            <!-- <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-clipboard-list"></i>
                </span>
                <span class="d-none d-sm-inline-block">Summary</span>
              </template>
              <b-card-text class="summary">
                <div class="row">
                  <div class="col-lg-7 col-md-8 col-sm-12">
                    <b-form-group
                        class="mb-3"
                        label-cols-xs="12"
                        label-cols-sm="12"
                        label-cols-md="7"
                        label-cols-lg="6"
                        label="Settlement Amount"
                        label-for="text"
                      >
                        <b-form-input
                          for="text"
                          readonly
                          :value="parseFloat(loan_info.loan_settlement || 0 ).toFixed(2)"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group 
                        class="mb-3"
                        label-cols-sm="12"
                        label-cols-md="7"
                        label-cols-lg="6"
                        label="Interest Rate"
                        label-for="text"
                      >
                        <b-form-input
                          for="text"
                          readonly
                          :value="calculateInterest"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group 
                        v-show="installment.type !== 'PP' "
                        class="mb-3"
                        label-cols-sm="12"
                        label-cols-md="7"
                        label-cols-lg="6"
                        label="Length of Mortgage"
                        label-for="text"
                      >
                        <b-form-input
                          for="text"
                          readonly
                          :value="calculateMortgage"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        class="mb-3"
                        label-cols-sm="12"
                        label-cols-md="7"
                        label-cols-lg="6"
                        label="Each Repayment"
                        label-for="text"
                      >
                        <b-form-input
                          for="text"
                          readonly
                          :value="parseFloat(installment.pay_per_term || 0).toFixed(2)"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        v-show="installment.type !== 'PP' "
                        class="mb-3"
                        label-cols-sm="12"
                        label-cols-md="7"
                        label-cols-lg="6"
                        label="Total Payment"
                        label-for="text"
                      >
                        <b-form-input
                          for="text"
                          readonly
                          :value="parseFloat(installment.term * installment.pay_per_term).toFixed(2)"
                        ></b-form-input>
                      </b-form-group>

                      <b-form-group
                        v-show="installment.type !== 'PP' "
                        class="mb-3"
                        label-cols-sm="12"
                        label-cols-md="7"
                        label-cols-lg="6"
                        label="Total Interest Paid"
                        label-for="text"
                      >
                        <b-form-input
                          for="text"
                          readonly
                          :value="parseFloat(installment.term * installment.pay_per_term - (loan_info.loan_settlement || 0 ) ).toFixed(2)"
                        ></b-form-input>
                      </b-form-group>
                  </div>
                </div>
              </b-card-text>
            </b-tab> -->
            <b-tab active>
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-table"></i>
                </span>
                <span class="d-none d-sm-inline-block">Detail</span>
              </template>
              <b-card-text class="installment-terms-cards">
                <div class="mb-2">
                  <a class="btn btn-outline-info btn-sm me-1" href="javascript:void(0)" v-show="!flag_edit_installment && loan_id !== '0' && installment.type == 'PP' && installment.status !== 'COMPLETE' && compute_user.permissions.includes('*')" @click="extend_pp">
                    Extend PP
                  </a>
                  <a class="btn btn-info btn-sm" href="javascript:void(0)" v-show="!flag_edit_installment && loan_id !== '0' && installment.status !== 'COMPLETE' && compute_user.permissions.includes('*')" @click="openPaymentModal(hidden_index,hidden_term_detail,'settlement')">
                    Full Payment
                  </a>   
                  <a class="btn btn-primary btn-sm" href="javascript:void(0)" v-show="installment.status == 'COMPLETE' && loan_id !== '0' && compute_user.permissions.includes('*')"  @click="additional_modal">
                    Additional
                  </a>


                  <b-form-group class="mt-3 mb-3" v-show="original_installment_details.length > 1 && compute_user.permissions.includes('*')">
                    <b-form-checkbox
                      value="true"
                      unchecked-value="false"
                      v-model="show_all_terms"
                      plain
                      >Show all installment
                    </b-form-checkbox>
                  </b-form-group>
                </div>
                
                <div class="row">
                  <div v-show="show_all_terms == 'false' " class="col-lg-4" :key="index" v-for="(item,index) in installment_terms">
                    <b-card
                      id="table_installment"
                      style="min-height:330px; overflow:hidden"
                      header-class="bg-transparent"
                      class="border text-left"
                      :class="item.className"
                      :footer="item.status == 'PAID' ? '' : calculateDayAgo(item.due_date)"
                      >
                      <template v-slot:header>
                        <h6 class="my-0 text-center " :class="item.classText">
                          <!-- <i class="mdi mdi-block-helper me-3"></i> -->
                          <span v-if="item.status == 'PAID'">付款日期 <b>{{ item.due_date }}</b>
                            <br>
                            <span>已付款 <b>{{ formatNumber(item.paid_amount) }}</b></span>
                          </span>

                          <span v-else>还帐日期 <b>{{ item.due_date }}</b>
                            <br>
                            <span>应付款 <b>{{ formatNumber(item.installment_amount) }}</b></span>
                          </span>
                        </h6>
                      </template>

                      <p class="mt-1 mb-1" v-if="index == 0 && loan_id !== '0'">Principal <b class="float-end">{{ formatNumber(loan_info.loan_settlement) }}</b></p>
                      <p class="mt-1 mb-1" v-else-if="item.remaining_loan !== ''">剩 <b class="float-end">{{ formatNumber( installment_terms[index-1].remaining_loan ) }}</b></p>

                      <hr v-if="item.remaining_loan !== ''" class="schedule-table-hr">

                      <div class="schedule-table">
                        <div>应付款</div>
                        <div><b> {{ formatNumber(item.installment_amount) }}</b></div>
                      </div>
                      <div class="schedule-table">
                        <div>利息</div>
                        <div><b>+ {{ formatNumber(item.late_charge) }}</b></div>
                      </div>
                      <div class="schedule-table">
                        <div>折扣</div>
                        <div><b>- {{ formatNumber(item.discount) }}</b></div>
                      </div>
                      <hr class="schedule-table-hr" v-show="item.paid_amount !== '' ">
                      <div class="schedule-table" v-show="item.paid_amount !== '' ">
                        <div>总</div>
                        <div><b>{{ formatNumber(total_tobe_paid(item.installment_amount,item.late_charge,item.discount)) }}</b></div>
                      </div>
                      <div class="schedule-table" v-show="item.paid_amount !== '' ">
                        <div>付款</div>
                        <div><b>{{ formatNumber(total_payment_made(item.paid_amount,item.late_charge)) }}</b></div>
                      </div>
                      <div class="schedule-table" v-show="item.paid_amount !== '' ">
                        <div>付款日期</div>
                        <div><b>{{ formatDate(item.paid_date) }}</b></div>
                      </div>
                      <div class="schedule-table" v-show="item.paid_amount !== '' ">
                        <div>剩下</div>
                        <div><b>{{ formatNumber(item.remaining_loan) }}</b></div>
                      </div>

                      <div v-if="item.flag_action_btn && compute_user.permissions.includes('*')" style="margin-top:1rem; display:flex; justify-content:space-between" >
                        <a v-show="item.id" href="javascript:void(0)" class="w-100 btn btn-outline-primary btn-sm" @click="openPaymentModal(index, item)">付款</a>
                        <!-- <a v-show="item.id" href="javascript:void(0)" class="w-45 btn btn-info btn-sm" @click="openPaymentModal(index, item,'settlement')">清数</a> --> 
                      </div>
                      <div v-if="item.status == 'PAID'" style="margin-top:1rem; display:flex; justify-content:space-between">
                        <!-- <a v-show="item.id" href="javascript:void(0)" class="btn btn-outline-secondary btn-sm" :class=" compute_user.permissions.includes('*') ? 'w-45' : 'w-100' " @click="viewPayment(index, item)">查看</a> -->
                        <a v-show="compute_user.permissions.includes('*') " href="javascript:void(0)" class="w-100 btn btn-secondary btn-sm" @click="EditPayment(index, item)">Edit</a>
                      </div>
                    </b-card>
                  </div>

                  <div v-show="show_all_terms == 'true' ">
                    <div class="custom-accordion" :key="index" v-for="(header,index) in original_installment_details">
                      <b-card>
                        <h6>
                          <a
                            href="javascript: void(0);"
                            :class="[ header.status == 'CANCELLED' ? 'text-danger' : header.status == 'ONGOING' ? 'text-success' : 'text-dark']"
                            data-toggle="collapse"
                            v-b-toggle="'fulldetailins-' + index"
                            
                          >
                          <!-- v-b-toggle.fulldetailins-collapse -->
                          <i class="mdi mdi-chevron-up float-end accor-down-icon"></i>
                            {{ header.start_date }} {{ header.type }} {{ header.status }}
                          </a>
                        </h6>

                        <b-collapse visible :id="'fulldetailins-'+index">
                          <div class="row">
                            <div>
                              <table class="table table-sm">
                                <thead>
                                  <tr>
                                    <th>Due Date</th>
                                    <th>Installment</th>
                                    <th>Late</th>
                                    <th>Disc</th>
                                    <th>Paid Date</th>
                                    <th>Amount</th>
                                    <th>Remaining</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  <tr v-for="(item,ind) in header.terms" :key="ind" :class="{'text-muted' : item.status == 'VOID' }">
                                    <td>{{item.due_date}}</td>
                                    <td>{{item.installment_amount}}</td>
                                    <td>{{item.late_charge}}</td>
                                    <td>{{item.discount}}</td>
                                    <td>{{item.paid_date}}</td>
                                    <td>{{item.paid_amount}}</td>
                                    <td>{{item.remaining_loan}}</td>
                                    <td>{{item.status}}</td>
                                  </tr>
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </b-collapse>
                      </b-card>
                    </div>
                  </div>
                </div>
                
                <b-modal
                    id="modal-payment"
                    centered
                    :title="'Make '+modal_make_payment.mode"
                    title-class="font-18"
                    hide-footer
                    >
                    <div class="mb-2 row">
                      <div class="col-md-6" v-show="modal_make_payment.mode !== 'settlement' ">
                        <b-form-group
                          label="Schedule / 期数"
                          label-for="form-loan-1"
                          class="mb-3"
                        >
                          <b-form-input
                            id="form-loan-1"
                            type="text"
                            readonly disabled
                            v-model="modal_make_payment.due_date"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-6" v-show="modal_make_payment.mode !== 'settlement' ">
                        <b-form-group
                          label="Amount Due / 应付款"
                          label-for="form-loan-22"
                          class="mb-3"
                        >
                          <b-form-input
                            id="form-loan-22"
                            type="number"
                            readonly
                            v-model="modal_make_payment.amount_due"
                          ></b-form-input>
                        </b-form-group>
                      </div>

                      <div class="col-md-6" v-show="modal_make_payment.mode == 'settlement' ">
                        <b-form-group
                          label="Settlement Amount / 总数"
                          label-for="form-loan-22"
                          class="mb-3"
                        >
                          <b-form-input
                            id="form-loan-22"
                            type="number"
                            readonly
                            v-model="compute_settlement"
                          ></b-form-input>
                        </b-form-group>
                      </div>

                    </div>

                    <div class="mb-2 row">
                      <div class="col-md-6">
                        <b-form-group
                          label="Interest / 利息"
                          label-for="form-loan-3"
                          class="mb-3"
                        >
                          <b-form-input
                            id="form-loan-3"
                            type="number"
                            v-model="modal_make_payment.interest"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group
                          label="Discount / 折扣"
                          label-for="form-loan-4"
                          class="mb-3"
                        >
                          <b-form-input
                            id="form-loan-4"
                            type="number"
                            v-model="modal_make_payment.discount"
                          ></b-form-input>
                        </b-form-group>
                      </div>
                    </div>

                    <div class="mb-2 row">
                      <div class="col-md-6">
                        <b-form-group
                          label="Payment Date / 付款日期"
                          label-for="form-loan-5"
                          class="mb-3"
                        >
                          <date-picker id="form-loan-5" v-model="modal_make_payment_payment_date2" @input="modal_make_payment.payment_date = fixDate($event)" :first-day-of-week="1" lang="en" format="YYYY-MM-DD"></date-picker>
                        </b-form-group>
                      </div>
                      <div class="col-md-6">
                        <b-form-group
                          label="Paid / 付款"
                          label-for="form-loan-2"
                          class="mb-3"
                        >
                          <b-form-input
                            id="form-loan-2"
                            type="number"
                            v-model="modal_make_payment.amount"
                          ></b-form-input>
                        </b-form-group>
                      </div> 
                      <div class="col-md-6">
                        <b-form-group
                          label="Initial"
                          label-for="form-loan-left"
                          class="mb-3"
                        >
                          <b-form-input
                            id="form-loan-left"
                            type="number"
                            readonly
                            v-model="initialAmt"
                          ></b-form-input>
                        </b-form-group>
                      </div> 
                      <div class="col-md-6">
                        <b-form-group
                          label="Balance / 剩"
                          label-for="form-loan-left"
                          class="mb-3"
                        >
                          <b-form-input
                            id="form-loan-left"
                            type="number"
                            v-model="modal_make_payment.remaining_loan"
                          ></b-form-input>
                        </b-form-group>
                      </div>  
                    </div>

                    <!-- <div class="mb-2 row" v-show="installment.type == 'PP' && modal_make_payment.mode == 'settlement' ">
                      <b-form-group
                        label-for="form-loan-payprincipal"
                        class="mb-3"
                      >
                        <b-form-checkbox
                          id="form-loan-payprincipal"
                          value="1"
                          unchecked-value="0"
                          v-model="pay_mother"
                          plain
                          >Pay Principal</b-form-checkbox
                        >
                      </b-form-group>
                    </div> -->

                    <div class="modal-footer">
                      <!-- :disabled="!compute_valid_payment || disabled_button"  -->
                      <button class="btn btn-success ms-1" @click="makePayment">
                        <i class="uil uil-file-alt me-1"></i> Save
                      </button>
                      <button class="btn btn-danger" @click="closePaymentModal">
                        <i class="uil uil-times me-1"></i> Cancel
                      </button>
                    </div>
                  </b-modal>
              </b-card-text>
            </b-tab>
            <b-tab style="padding: 0px">
              <template v-slot:title>
                <span class="d-inline-block d-sm-none">
                  <i class="fas fa-money-check-alt"></i>
                </span>
                <span class="d-none d-sm-inline-block">Log History</span>
              </template>
              <b-card-text class="payment-history">
                <div class="row">
                  <div class="col-md-12 table-responsive text-center" style="max-height:450px; overflow-y:scroll;">
                    <b-spinner
                      v-show="loading_log"
                      class="m-2"
                      variant="primary"
                      role="status"
                    ></b-spinner>
                    <table class="table table-borderless table-sm text-center" v-show="!loading_log">
                      <thead>
                        <tr>
                          <th>Type</th>
                          <th>日期</th>                           
                          <th>利息</th>
                          <th>折扣</th>
                          <th>Credit</th>
                          <th>Debit</th>
                          <th>剩</th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr :key="index" v-for="(item,index) in log_history">
                          <td width="10%">{{ item.type }}</td>
                          <td width="20%">{{ item.date }}</td>
                          <td width="10%">{{ item.interest }}</td>
                          <td width="10%">{{ item.discount }}</td>
                          <td width="15%">{{ item.credit }}</td>
                          <td width="15%">{{ item.debit }}</td>
                          <td width="15%">{{ item.remaining_loan }}</td>
                        </tr>
                      </tbody>
                      <tfoot>
                        <tr>
                          <th></th>
                          <th></th>      
                          <th>{{ compute_total_interest }}</th>
                          <th>{{ compute_total_discount }}</th>
                          <th>{{ compute_total_credit }}</th>
                          <th>{{ compute_total_debit }}</th>
                          <th></th>
                        </tr>
                      </tfoot>
                    </table>
                  </div>
                </div>
              </b-card-text>
            </b-tab>
          </b-tabs>

          <b-modal
            id="modal-bonus-payment"
            centered
            title="Additional Payment"
            title-class="font-18"
            hide-footer
            >

            <div class="mb-2 row">
              <div class="col-md-6">
                <b-form-group
                  label="Date / 日期"
                  label-for="form-loan-55"
                  class="mb-3"
                >
                  <date-picker id="form-loan-55" v-model="add_payment_date" @input="add.payment_date = fixDate($event)" :first-day-of-week="1" lang="en" format="YYYY-MM-DD"></date-picker>
                </b-form-group>
              </div>
              <div class="col-md-6">
                <b-form-group
                  label="Paid / 付款"
                  label-for="form-loan-22"
                  class="mb-3"
                >
                  <b-form-input
                    id="form-loan-22"
                    type="number"
                    v-model="add.amount"
                  ></b-form-input>
                </b-form-group>
              </div>  
            </div>

            <div class="modal-footer">
              <button :disabled="flag_payment" class="btn btn-success ms-1" @click="additional_payment">
                <i class="uil uil-file-alt me-1"></i> Save
              </button>
              <button class="btn btn-danger" @click="closeAdditionalModal">
                <i class="uil uil-times me-1"></i> Cancel
              </button>
            </div>
          </b-modal>

        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
  import DatePicker from "vue2-datepicker";
  import moment from 'moment'
  import globalFunction from '@/assets/js/global_function.js'
  import Swal from "sweetalert2"
  export default {
    name: 'loanInstallment',  
    components: { DatePicker },
    props: {
      loan_id: {
        type: String,
        default: '0'
      },
      loan_info: {
        type: Object,
      },
      // isEdit: {
      //   type: Boolean,
      //   default: false
      // },
      // fromWhere: {
      //   type: String,
      //   default: ''
      // }
    },
    data() {
      return {
        disabled_button: false,
        flag_edit_installment: false,
        backup_installment: {},

        installment:{
          id: '',
          pay_per_term: '',
          term: '',
          day_term: '',
          start_date: '',
          balance: '',
          type: 'REGULAR',
          status: 'ONGOING',
          installment_json: '',
          loan_uuid: ''
        },
        installment_start_date: '',
        installment_terms:[],

        // loan_info: {},

        timeout: null,

        modal_make_payment_payment_date2: '',
        modal_make_payment:{
          index: '',
          due_date: '',
          amount_due: '',
          interest: '',
          discount: '',
          payment_date: '',
          amount: '',
          mode: 'payment',
          id: '',
          api_mode: '',
          remaining_loan:''
        },
        pay_mother: "0",

        add:{
          amount: '',
          payment_date: '',
        },
        add_payment_date: '',


        loading_log: true,
        log_history: [],
        advance_insert_terms_counter: 0,
        hidden_term_detail: '',
        hidden_index: '',

        backup_installment_terms: [],
        original_installment_details: [],
        show_all_terms: 'false',

        flag_save_installment: false,
        flag_payment: false
      }
    },
    watch: {
      // flag_edit_installment(){
      //   if(this.flag_edit_installment){
      //     this.backup_installment = JSON.parse(JSON.stringify(this.installment));
      //   }
      //   else{
      //     this.installment = this.backup_installment
      //     this.backup_installment = {}
      //   }
      // },
      // loan_info: {
      //   // This will let Vue know to look inside the array
      //   deep: true,

      //   // We have to move our method to a handler field
      //   handler(){
      //     this.loan_info = JSON.parse(JSON.stringify(this.loan_info));
      //   }
      // },
      // installment: {
      //   // This will let Vue know to look inside the array
      //   deep: true,

      //   // We have to move our method to a handler field
      //   handler(){
      //     if(this.installment.type == 'PP'){
      //       if(this.installment.pay_per_term !== '' &&
      //           this.installment.day_term !== '' &&
      //           this.installment.start_date !== '' )
      //       {
      //         this.generateInstallmentSchedule()
      //       }
      //     }
      //     if(this.installment.pay_per_term !== '' &&
      //         this.installment.term !== '' &&
      //         this.installment.day_term !== '' &&
      //         this.installment.start_date !== '' )
      //     {
      //       this.generateInstallmentSchedule()
      //     }

      //     this.installment.installment_json = JSON.stringify(this.installment_terms)

      //     if (this.timeout) clearTimeout(this.timeout)
      //     this.timeout = setTimeout(() => {
      //       this.$emit('updateInstallment', this.installment)
      //     }, 3000)
          
      //   }
      // },


      loan_info: {
        // This will let Vue know to look inside the array
        deep: true,

        // We have to move our method to a handler field
        handler(){
          
        }
      },
      loan_id(){
        // console.log('from loan installment',this.loan_id)
        this.log_history = []
        this.backup_installment_terms = []
        if(this.loan_id == '0' || this.loan_id == 0){
          this.resetAll()
        }
        else{
          this.load_detail()
        }
      }
    },
    mounted(){
      if(this.loan_id !== '0' || this.loan_id !== 0){
        this.load_detail()
      }
    },
    computed: {
      compute_total_interest(){
        let amount = 0
        this.log_history.forEach(function(item){
          amount += parseFloat(item.interest || 0)
        })
        return amount.toFixed(2)
      },
      compute_total_discount(){
        let amount = 0
        this.log_history.forEach(function(item){
          amount += parseFloat(item.discount || 0)
        })
        return amount.toFixed(2)
      },
      compute_total_credit(){
        let amount = 0
        this.log_history.forEach(function(item){
          amount += parseFloat(item.credit || 0)
        })
        return amount.toFixed(2)
      },
      compute_total_debit(){
        let amount = 0
        this.log_history.forEach(function(item){
          amount += parseFloat(item.debit || 0)
        })
        return amount.toFixed(2)
      },
      compute_settlement(){
        let index = this.modal_make_payment.index
        let amount = 0
        if(index > 0 && this.modal_make_payment.mode !== 'settlement'){
          amount = parseFloat(this.modal_make_payment.amount_due)
        }
        else if(index > 0 && this.modal_make_payment.mode == 'settlement'){
          amount = parseFloat(this.installment_terms[index-1].remaining_loan)
        }
        else{
          amount = parseFloat(this.loan_info.loan_settlement)
        }
        
        return amount
      },
      compute_valid_payment(){
        let total = 0
        let index = this.modal_make_payment.index

        if(index == 0 && this.modal_make_payment.mode == 'settlement'){
          total = parseFloat(this.loan_info.loan_settlement)
        }
        else if(index == 0 && this.modal_make_payment.mode == 'payment'){
          total = parseFloat(this.modal_make_payment.amount_due)
        }

        else if(index > 0 && this.modal_make_payment.mode == 'settlement'){
          total = parseFloat(this.installment_terms[index-1].remaining_loan)
        }
        else if(index > 0 && this.modal_make_payment.mode !== 'settlement'){
          total = parseFloat(this.modal_make_payment.amount_due)
        }
        else{
          total = parseFloat(this.loan_info.loan_settlement)
        }

        // if(this.modal_make_payment.index == 0 && this.modal_make_payment.mode == 'settlement'){
        //   total = parseFloat(this.loan_info.loan_settlement)
        // }
        // else{
        //   total = parseFloat(this.modal_make_payment.amount_due)
        // }
        
        total += parseFloat(this.modal_make_payment.interest || 0)

        let payment = parseFloat(this.modal_make_payment.amount) + parseFloat(this.modal_make_payment.discount || 0)

        if(payment == total){
          return true
        }
        else{
          if(this.pay_mother == "1"){
            return true
          }
          return false
        }
      },
      /*compute_remain_loan(){
        let prev_remaining = 0
        // let balance = 0
        let index = parseInt(this.modal_make_payment.index)

        if( index == 0 || this.installment.type == 'PP'){
          prev_remaining = parseFloat(this.loan_info.loan_settlement)

          if(this.installment.type == 'PP' && this.pay_mother == "1"){
            prev_remaining -= parseFloat(this.modal_make_payment.amount)
          }
          if(this.installment.type == 'PP' && this.pay_mother == "0"){
            prev_remaining = parseFloat(this.installment_terms[index-1].remaining_loan)
          }
        }
        else{
          prev_remaining = parseFloat(this.installment_terms[index-1].remaining_loan)
        }


        if(this.installment.type !== 'PP'){

          prev_remaining -= parseFloat(this.installment_terms[index].installment_amount)
          
          // if(this.modal_make_payment.mode == 'settlement'){
          //   if(this.compute_valid_payment){
          //     prev_remaining = 0
          //   }
          // }
        }
        
        return prev_remaining

        // balance = parseFloat(prev_remaining) - parseFloat(this.installment_terms[index].installment_amount)
        // if(this.installment.type == 'PP'){
        //   balance = prev_remaining
        // }
        
        
      },*/
      // calculateMortgage(){
      //   let total_days = parseFloat(this.installment.term * this.installment.day_term)
      //   return (total_days || 0)+' days'
      // },
      // calculateInterest(){  
      //   if(this.loan_info.loan_settlement !== '0' || this.loan_info.loan_settlement !== 0){
      //     if(this.installment.pay_per_term !== '' && this.installment.term !== ''){
      //       let initial_amt = parseFloat(this.loan_info.loan_settlement)
      //       let total_paid = parseFloat(this.installment.pay_per_term * this.installment.term)
      //       let difference = total_paid - initial_amt
      //       let ratio = (difference / initial_amt).toFixed(2)
      //       let percentage = (parseFloat(ratio) * 100).toFixed(2)
      //       return ( isNaN(percentage) ? '0' : percentage+"%")
      //     }
      //     else{
      //       return 0
      //     }
      //   }
      //   else{
      //     return 0
      //   }
      // },
      compute_user(){
        return this.$store.state.authfack.user
      },
      initialAmt(){
        let index = parseInt(this.modal_make_payment.index)
        if(this.modal_make_payment.index == '0' || this.modal_make_payment.index == 0){
          return parseFloat(this.loan_info.loan_settlement).toFixed(2)
        }
        else{
          return parseFloat(this.installment_terms[index-1].remaining_loan).toFixed(2)
        }
        
      }
    },
    methods: {
      ...globalFunction,
      compute_item_remaining(remaining_loan, amt_due, type){
        let a = parseFloat(amt_due)
        let b = parseFloat(remaining_loan)
        if(type == 'PP'){
          a = 0
        }
        return (a+b).toFixed(2)
      },
      total_tobe_paid(amt_due,interest,discount){
        let a = parseFloat(amt_due)
        let b = parseFloat(interest || 0)
        let c = parseFloat(discount || 0)

        return (a+b-c).toFixed(2)
      },
      total_payment_made(payment){
        let a = parseFloat(payment)
        // let b = parseFloat(interest || 0)
        // let c = parseFloat(discount || 0)

        return a.toFixed(2)
      },
      openPaymentModal(index, term_detail, payment_for = ''){
        let self = this

        this.closePaymentModal()

        self.modal_make_payment.index = index
        self.modal_make_payment.id = term_detail.id || 0
        self.modal_make_payment.due_date = term_detail.due_date
        self.modal_make_payment.amount_due = term_detail.installment_amount
        self.modal_make_payment.interest = ''
        self.modal_make_payment.discount = ''
        self.modal_make_payment_payment_date2 = new Date()
        self.modal_make_payment.payment_date = term_detail.paid_date || moment(new Date()).format('YYYY-MM-DD')
        self.modal_make_payment.amount = ''
        self.modal_make_payment.api_mode = 'new'
        if(payment_for == 'settlement'){
          self.modal_make_payment.mode = 'settlement'
        }
        else{
          self.modal_make_payment.mode = 'payment'
        }
        self.$bvModal.show('modal-payment')
      },
      closePaymentModal(){
        this.modal_make_payment_payment_date2 = '',
        this.modal_make_payment = {
          index: '',
          due_date: '',
          amount_due: '',
          interest: '',
          discount: '',
          payment_date: '',
          amount: '',
          mode: 'payment',
          id: '',
          api_mode: '',
          remaining_loan: ''
        }
        this.pay_mother = "0"
        this.$bvModal.hide('modal-payment')
      },
      EditPayment(index, term_detail){
        console.log(index,term_detail)
        let self = this
        this.closePaymentModal()

        self.modal_make_payment.index = index
        self.modal_make_payment.id = term_detail.id || 0
        self.modal_make_payment.due_date = term_detail.due_date
        self.modal_make_payment.amount_due = term_detail.installment_amount
        self.modal_make_payment.interest = term_detail.late_charge
        self.modal_make_payment.discount = term_detail.discount
        self.modal_make_payment_payment_date2 = moment(term_detail.paid_date, 'YYYY-MM-DD').toDate()
        self.modal_make_payment.payment_date = term_detail.paid_date
        self.modal_make_payment.amount = term_detail.paid_amount
        self.modal_make_payment.remaining_loan = term_detail.remaining_loan
        self.modal_make_payment.api_mode = 'update'


        self.$bvModal.show('modal-payment')
      },
      compute_remain_loan(){
        let index = parseInt(this.modal_make_payment.index)
        let prev_remaining = parseInt(this.modal_make_payment.index) == 0 ? parseFloat(this.loan_info.loan_settlement) : parseFloat(this.installment_terms[index-1].remaining_loan)
        if(this.modal_make_payment.remaining_loan !== ''){
          return prev_remaining - parseFloat(this.modal_make_payment.amount) - parseFloat(this.modal_make_payment.discount || 0)
          // prev_remaining = parseFloat(this.modal_make_payment.remaining_loan)
          // return prev_remaining -= parseFloat(this.modal_make_payment.amount)
        }
        else{
          
          if( index == 0 ){
            if(this.modal_make_payment.remaining_loan !== ''){
              prev_remaining = parseFloat(this.modal_make_payment.remaining_loan)
              return prev_remaining -= parseFloat(this.modal_make_payment.amount) - parseFloat(this.modal_make_payment.discount || 0)
            }
            else{
              prev_remaining = parseFloat(this.loan_info.loan_settlement)
              console.log('a',prev_remaining)
              return prev_remaining -= parseFloat(this.modal_make_payment.amount) - parseFloat(this.modal_make_payment.discount || 0)
            }
          }

          if(this.installment.type !== 'PP'){
            console.log('pnb')
            console.log('aaa',prev_remaining)
            // return prev_remaining -= parseFloat(this.installment_terms[index].installment_amount)
            return prev_remaining - parseFloat(this.modal_make_payment.amount) - parseFloat(this.modal_make_payment.discount || 0)
            
          }

          if(this.installment.type == 'PP' && this.pay_mother == "1"){
            console.log('b',prev_remaining)
            return prev_remaining -= parseFloat(this.modal_make_payment.amount) - parseFloat(this.modal_make_payment.discount || 0)
            
          }
          if(this.installment.type == 'PP' && this.pay_mother == "0"){
            console.log('c',prev_remaining)
            return prev_remaining = parseFloat(this.installment_terms[index-1].remaining_loan)
          }
        }
        
        
        // if(this.installment.type !== 'PP'){
        //   console.log('pnb')
        //   prev_remaining -= parseFloat(this.installment_terms[index].installment_amount)
        //   console.log('aaa',prev_remaining)
        // }


        // if(this.installment.type !== 'PP' && index > 0){
        //   prev_remaining -= parseFloat(this.installment_terms[index].installment_amount)
          
        //   console.log('d',prev_remaining)
        // }
        // else{
        //   prev_remaining = parseFloat(this.installment_terms[index-1].remaining_loan)
        // }

      
          
          // if(this.modal_make_payment.mode == 'settlement'){
          //   if(this.compute_valid_payment){
          //     prev_remaining = 0
          //   }
          // }
        
        
        // return prev_remaining        
      },
      makePayment(){
        let self = this

        // if(!this.compute_valid_payment){
          // Swal.fire("Invalid Payment", "Wrong payment amount", "danger");
          // alert('wrong payment amount')
        // }
        // else{
          // let total_tobe_paid=0,b=0,c=0

          if(this.installment_terms[this.modal_make_payment.index].remaining_loan !== '' && this.modal_make_payment.api_mode !== 'update'){
            this.modal_make_payment.id = this.installment_terms[this.modal_make_payment.index + 1].id
            this.modal_make_payment.due_date = this.installment_terms[this.modal_make_payment.index + 1].due_date
            this.modal_make_payment.amount_due = this.installment_terms[this.modal_make_payment.index + 1].installment_amount            
          }

          
          //execute
          let baseURI = 'installment_terms/'+self.modal_make_payment.id;
          let formData = new FormData()

          let payment_amount = 0
          payment_amount = parseFloat(self.modal_make_payment.amount)

          // payment_amount = parseFloat(self.modal_make_payment.amount) - parseFloat(self.modal_make_payment.interest || 0) + parseFloat(self.modal_make_payment.discount || 0)

          // let balance = this.compute_remain_loan()
          // if(this.pay_mother == '1'){
          //   if(balance <= 0 ){
          //     balance = 0
          //   }
          // }

          // if(this.modal_make_payment.mode == "settlement" && this.compute_valid_payment){
          //   self.modal_make_payment.amount_due = 0
          // }

          let installment_amt = parseFloat(self.modal_make_payment.amount_due || 0).toFixed(2)
          if(self.modal_make_payment.mode == 'settlement'){
            installment_amt = (self.compute_settlement).toFixed(2)
          }

          formData.append('_method', 'PUT' )
          formData.append('due_date', self.modal_make_payment.due_date)
          formData.append('installment_amount', installment_amt)
          formData.append('late_charge', parseFloat(self.modal_make_payment.interest || 0).toFixed(2))
          formData.append('paid_amount', payment_amount.toFixed(2))
          formData.append('paid_date', self.modal_make_payment.payment_date)
          formData.append('discount', parseFloat(self.modal_make_payment.discount || 0).toFixed(2))
          formData.append('remaining_loan', parseFloat(self.modal_make_payment.remaining_loan || 0).toFixed(2))
          formData.append('status', 'PAID')

          var obj = {
            due_date: self.modal_make_payment.due_date,
            installment_amount: installment_amt,
            late_charge: parseFloat(self.modal_make_payment.interest || 0).toFixed(2),
            paid_amount: payment_amount.toFixed(2),
            paid_date: self.modal_make_payment.payment_date,
            discount: parseFloat(self.modal_make_payment.discount || 0).toFixed(2),
            remaining_loan: parseFloat(self.modal_make_payment.remaining_loan || 0).toFixed(2),
            status: 'PAID'
          }
          
          console.log(baseURI,payment_amount,obj)

          // return false

          self.disabled_button = true
          self.$http.post(baseURI, formData)
            .then((result) => {
            if(result.data.status == 'success'){
              // console.log(result.data, 'make payment')
              self.disabled_button = false
              if(self.modal_make_payment.mode == 'settlement' && parseFloat(self.modal_make_payment.remaining_loan || 0) <= 0 ){
                self.update_installment_term_status('PAID')

                setTimeout(function(){
                  let baseURI2 = `installments/${self.installment.id}`
                  let formData2 = new FormData()
                  formData2.append('_method', 'PUT' )
                  formData2.append('status', 'COMPLETE' )
                  self.$http.post(baseURI2,formData2)
                    .then((result) => {
                    if(result.data.status == 'success'){
                      // console.log('update installment status to paid')

                      let baseURI3 = `loans/${self.loan_id}`
                      let formData3 = new FormData()
                      formData3.append('_method', 'PUT' )
                      formData3.append('loan_status', 'COMPLETE' )
                      self.$http.post(baseURI3,formData3)
                        .then((result) => {
                        if(result.data.status == 'success'){
                          // console.log('update loan status to complete')
                          self.$notify({
                            group: 'app',
                            title: 'Loan successfully completed',
                            type: 'success'
                          });
                          self.disabled_button = false
                          setTimeout(function(){
                            // window.location.reload();
                            self.load_detail()
                            self.$emit('refresh_page', self.loan_id)
                            self.$root.$emit('refresh', self.loan_id);
                          },350)
                          // self.load_detail()
                        }
                      })
                      // self.load_detail()
                    }
                  })
                },300)
              }
              else{
                self.$notify({
                  group: 'app',
                  title: 'Payment received!',
                  type: 'success'
                });
                self.load_detail()
              }
              self.closePaymentModal()
            }
          })
        // }
      },
      calculateDayAgo(due_date){
        let return_text = ''
        let a = new Date(moment(due_date, 'YYYY-MM-DD'))
        let b = moment().diff(a, 'days')

        if(moment(due_date, 'YYYY-MM-DD').isSame(moment(new Date(),"YYYY-MM-DD"),'day')){
          return_text = '今天'
        }
        else if(moment(due_date, "YYYY-MM-DD").isBefore(moment(new Date(), "YYYY-MM-DD"), 'day')){
          return_text = '迟了 '+b+' day/s'
        }
        else{
          return_text = '还有 '+(Math.abs(b)+1)+' 天'
        }

        return return_text
      },
      resetAll(){
        this.flag_edit_installment = false
        this.backup_installment = {}

        this.installment = {
          id: '',
          pay_per_term: '',
          term: '',
          day_term: '',
          start_date: '',
          balance: '',
          type: 'REGULAR',
          status: 'ONGOING',
          installment_json: '',
          loan_uuid: ''
        }
        this.installment_start_date = ''
        this.installment_terms = []
        // this.loan_info = {}
        this.timeout = null

        this.modal_make_payment_payment_date2 = '',
        this.modal_make_payment = {
          index: '',
          due_date: '',
          amount_due: '',
          interest: '',
          discount: '',
          payment_date: '',
          amount: '',
          mode: 'payment',
          id: '',
        }

        this.log_history = []
      },
      extend_pp(){
        let self = this
        let counter = 5

        for(let a = 0; a < counter; a++){
          let last_date = this.installment_terms[this.installment_terms.length - 1].due_date

          const baseURI = 'installment_terms'
          let formData = new FormData();
          formData.append('installment_id', this.installment.id)
          formData.append('due_date', moment(last_date, 'YYYY-MM-DD' ).add(parseInt(this.installment.day_term), 'days', true).format("YYYY-MM-DD"))
          formData.append('installment_amount', this.installment.pay_per_term)

          formData.append('late_charge', '')
          formData.append('discount', '')
          formData.append('paid_amount', '')
          formData.append('remaining_loan', '')

          formData.append('status', 'ONGOING');

          this.$http.post(baseURI,formData)
          .then((result) => {
            if(result.data.status == 'success'){
              // window.location.reload();
            }
          })

          this.installment_terms.push({
            status: 'ONGOING',
            due_date: moment(last_date, 'YYYY-MM-DD' ).add(parseInt(this.installment.day_term), 'days', true).format("YYYY-MM-DD"),
            installment_amount: '',
            late_charge: '',
            discount: '',
            paid_amount: '',
            paid_date: '',
            remaining_loan: ''
          })
        }

        this.installment_terms.splice(0, this.advance_insert_terms_counter)

        const baseURI = 'installments/'+this.installment.id
        let formData = new FormData();
        formData.append('installment_json', JSON.stringify(this.installment_terms));
        formData.append('_method', 'PUT');

        this.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            // window.location.reload();
            self.load_detail();
          }
        })
      },
      fixDate(event){
        if(event){
          return moment(event).format('YYYY-MM-DD');
        }
        else{
          return ''
        }
      },
      load_detail(){
        let self = this
        this.resetAll()
        self.advance_insert_terms_counter = 0
        self.backup_installment_terms = []
        this.original_installment_details = []
        let baseURI = 'installments/list';
        let formData = new FormData()
        formData.append('loan_uuid', self.loan_id)
        self.$http.post(baseURI, formData)
          .then((result) => {
          if(result.data.status == 'success'){
            // console.log(result.data, 'installment return data')
            this.original_installment_details = result.data.data
            if(result.data.data.length == 0){
              
              // no installment data
              this.flag_edit_installment = true
              this.loading_log = false
            }
            else{
              result.data.data.forEach(function(item){
                // item.terms.forEach(function(term){
                //   if(term.status == 'PAID'){
                //     term.className = 'border-success'
                //     term.classText = 'text-success'
                //     term.type = item.type
                //     self.installment_terms.push(term)

                //     self.advance_insert_terms_counter += 1

                //     //store in log history
                //     self.log_history.push({
                //       credit: '',
                //       debit: term.paid_amount,
                //       type: item.type,
                //       date: term.paid_date,
                //       interest: term.late_charge,
                //       discount: term.discount,
                //       remaining_loan: term.remaining_loan
                //     })
                //   }
                // })
                if(item.status == 'CANCELLED' ){
                  item.terms.forEach(function(term){
                    if(term.status == 'PAID'){
                      term.className = 'border-success'
                      term.classText = 'text-success'
                      term.type = item.type
                      self.advance_insert_terms_counter += 1
                      self.installment_terms.push(term)
                      self.backup_installment_terms.push(term)
                      //store in log history
                      self.log_history.push({
                        credit: '',
                        debit: term.paid_amount,
                        type: item.type,
                        date: term.paid_date,
                        interest: term.late_charge,
                        discount: term.discount,
                        remaining_loan: term.remaining_loan
                      })
                    }
                  })
                }

                if(item.status == 'ONGOING' || item.status == 'COMPLETE'){
                  self.flag_edit_installment = false

                  self.installment.balance = item.balance 
                  self.installment.created_at = item.created_at 
                  self.installment.created_by = item.created_by 
                  self.installment.day_term = item.day_term 
                  self.installment.deleted_at = item.deleted_at 
                  self.installment.deleted_by = item.deleted_by 
                  self.installment.id = item.id 
                  self.installment.installment_json = item.installment_json 
                  self.installment.loan_uuid = item.loan_uuid 
                  self.installment.pay_per_term = item.pay_per_term 
                  self.installment.start_date = item.start_date 
                  self.installment.status = item.status 
                  self.installment.term = item.term 
                  self.installment.type = item.type 
                  self.installment.updated_at = item.updated_at 
                  self.installment.updated_by = item.updated_by 

                  self.installment_start_date = new Date(moment(item.start_date, 'YYYY-MM-DD'))

  
                  item.terms.forEach(function(term){
                    if(term.status == 'PAID' && term.paid_amount !== '' && term.paid_amount !== '0'){
                      term.className = 'border-success'
                      term.classText = 'text-success'
                      term.type = item.type
                      
                      self.installment_terms.push(term)
                      self.backup_installment_terms.push(term)

                      //store in log history
                      self.log_history.push({
                        credit: '',
                        debit: term.paid_amount,
                        type: item.type,
                        date: term.paid_date,
                        interest: term.late_charge,
                        discount: term.discount,
                        remaining_loan: term.remaining_loan
                      })
                    }
                    else{
                      if(item.status !== 'COMPLETE'){
                        self.installment_terms.push(term)

                      }
                      
                    }
                  })
                }


                else{
                  self.flag_edit_installment = true
                }

                self.loading_log = true
              })

              setTimeout(function(){
                //store in log history
                self.log_history.unshift({
                  credit: self.loan_info.loan_capital,
                  debit: '',
                  type: self.installment.type,
                  date: self.loan_info.loan_date,
                  interest: '',
                  discount: '',
                  remaining_loan: ''
                })

                self.loading_log = false
              },3500)
              self.load_bonuses()
              self.highlightTable()

            }
            
            // self.$emit('updateInstallment', self.installment)
          }
        })
      },
      load_bonuses(){
        let self = this
        const baseURI = 'bonuses/list'
        let formData = new FormData();
        formData.append('loan_uuid', self.loan_id);
        this.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            result.data.data.forEach(function(item){
              self.log_history.push({
                type: 'Extra',
                date: item.paid_date,
                interest: '0',
                discount: '0',
                credit: '0',
                debit: item.paid_amount,
                remaining_loan: '0'
              })
            })
            
            // console.log(result.data.data)
          }
        })
      },
      update_installment_term_status(action){
        let self = this
        let new_array = []
        self.installment_terms.forEach(function(item){
          if(item.status !== 'PAID'){
            new_array.push(item.id)
          }
        })

        // console.log(new_array, action)
        // return false
        if(new_array.length > 0){
          let baseURI = 'installment_terms/update_status'
          let formData = new FormData()
          // formData.append('term_ids', JSON.stringify(new_array));
          formData.append('term_ids', new_array);
          formData.append('status', action);
          self.$http.post(baseURI,formData)
            .then((result) => {
            if(result.data.status == 'success'){
              // console.log('update termsss to '+action)
              self.$notify({
                group: 'app',
                title: 'Installment successfully '+action,
                type: 'success'
              });
            }
          })
        }
      },
      cancel(){
        let self = this
        Swal.fire({
          title: "Are you sure?",
          text: "Confirm delete installment?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            //before update, check paid status from installment terms
            //use first one to check will do
            self.installment_terms.splice(0, self.advance_insert_terms_counter)
            let flag_paid_exist = self.installment_terms[0].status == 'PAID' ? true : false
            if(!flag_paid_exist){

              console.log('aabbcc')
              // return false
              //call delete api
              let baseURI = `installments/${self.installment.id}`
              self.$http.delete(baseURI)
                .then((result) => {
                if(result.data.status == 'success'){
                  Swal.fire("Deleted!", "Your installment plan has been deleted.", "success");
                  // console.log('delete installments success')
                  // self.$notify({
                  //   group: 'app',
                  //   title: 'Installment successfully cancel',
                  //   type: 'success'
                  // });
                  //reset installment component
                  self.load_detail()
                }
              })
            }
            else{

              console.log('ddeeff')
              // return false
              let baseURI = `installments/${self.installment.id}`
              let formData = new FormData()
              formData.append('_method', 'PUT' )
              formData.append('status', 'CANCELLED');
              self.$http.post(baseURI,formData)
              .then((result) => {
                if(result.data.status == 'success'){
                  Swal.fire("Cancelled!", "Your installment plan has been cancelled.", "success");
                  // console.log('update installments to cancelled')
                  self.update_installment_term_status('VOID')  
                  self.load_detail()
                }
              })
            }
            
          }
        });
        /*var r = confirm("Confirm delete installment?");
        if (r == true) {
          //before update, check paid status from installment terms
          //use first one to check will do
          self.installment_terms.splice(0, self.advance_insert_terms_counter)
          let flag_paid_exist = self.installment_terms[0].status == 'PAID' ? true : false
          if(!flag_paid_exist){
            //call delete api
            let baseURI = `installments/${self.installment.id}`
            self.$http.delete(baseURI)
              .then((result) => {
              if(result.data.status == 'success'){
                // console.log('delete installments success')
                self.$notify({
                  group: 'app',
                  title: 'Installment successfully cancel',
                  type: 'success'
                });
                //reset installment component
                self.load_detail()
              }
            })
          }
          else{
            let baseURI = `installments/${self.installment.id}`
            let formData = new FormData()
            formData.append('_method', 'PUT' )
            formData.append('status', 'CANCELLED');
            self.$http.post(baseURI,formData)
            .then((result) => {
              if(result.data.status == 'success'){
                // console.log('update installments to cancelled')
                self.update_installment_term_status('VOID')  
                self.load_detail()
              }
            })
          }
        }*/
      },

      closeAdditionalModal(){
        this.$bvModal.hide('modal-bonus-payment')
      },
      additional_modal(){
        this.add.amount = ''
        this.add.payment_date = ''
        this.add_payment_date = ''
        this.$bvModal.show('modal-bonus-payment')
      },
      additional_payment(){
        let self = this
        let baseURI = `bonuses`
        let formData = new FormData()
        formData.append('loan_uuid', self.loan_id )
        formData.append('paid_amount', self.add.amount);
        formData.append('paid_date', self.add.payment_date);
        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            // console.log('add bonus successful')
            self.$notify({
              group: 'app',
              title: 'Bonus added!',
              type: 'success'
            });
            self.load_detail()
            self.closeAdditionalModal()
          }
        })
      },
      save(){
        let self = this
        //step 2
          const baseURI = 'installments'

          self.installment_terms.splice(0, self.advance_insert_terms_counter)

          let formData = new FormData();
          formData.append('pay_per_term', self.installment.pay_per_term);
          formData.append('term', self.installment.term);
          formData.append('day_term', self.installment.day_term);
          formData.append('start_date', self.installment.start_date);
          formData.append('balance', self.installment.balance);
          formData.append('type', self.installment.type);
          formData.append('status', self.installment.status);
          formData.append('installment_json', JSON.stringify(self.installment_terms));
          formData.append('loan_uuid', self.loan_id);

          self.flag_save_installment = true
          self.$http.post(baseURI,formData)
          .then((result) => {
            if(result.data.status == 'success'){
              // self.$notify({
              //   group: 'app',
              //   title: 'Installment created!',
              //   type: 'success'
              // });
              Swal.fire("Installment", "Installment created", "success");
              self.flag_save_installment = false
              self.load_detail()
            }
          })
          .catch((err) => {
            if(err == 'session_timeout'){
              self.$notify({
                group: 'auth',
                title: 'User Authentication',
                text: 'Your session has expired, please login again',
                type: 'warn'
              });
              self.logout();
              self.$router.push({
                path: "/login",
              });
            }
          })
      },

      generateInstallmentSchedule(){
        // console.log('generateInstallmentSchedule')
        
        // let installment_id = 0
        // let paid_array = []

        // this.response_installment_list.forEach(function(item){
        //   if(item.status == "ONGOING" ){
        //     installment_id = item.id
        //   }
        // })

        // if(installment_id !== 0){
        //   //check for paid list first, 
        //   this.installment_terms.forEach(function(item){
        //     if(item.status == 'PAID'){
        //       paid_array.push(item)
        //     }
        //   })
        // }

        // console.log('old dates',this.installment_terms)
          
        //   this.installment_terms = []
        //   this.installment_terms = paid_array.slice()

        //   for(var a = 0; a < parseInt(this.repayment.term); a++){
        //     if(a == 0){
        //       this.installment_terms.push({
        //         status: 'ONGOING',
        //         due_date: moment(this.repayment_start_date2).format('YYYY-MM-DD'),
        //         installment_amount: this.repayment.amount,
        //         late_charge: '',
        //         discount: '',
        //         paid_amount: '',
        //         paid_date: '',
        //         remaining_loan: '',
        //         loan_type: this.zxczxc_loan_detail.loan_type
        //       })
        //       // parseFloat(this.repayment.term) * parseFloat(this.repayment.amount)
        //     }
        //     else{
        //       this.installment_terms.push({
        //         status: 'ONGOING',
        //         due_date: moment(this.repayment_start_date2).add(parseInt(this.repayment.days) * a, 'days', true).format("YYYY-MM-DD"),
        //         installment_amount: this.repayment.amount,
        //         late_charge: '',
        //         discount: '',
        //         paid_amount: '',
        //         paid_date: '',
        //         remaining_loan: '',
        //         loan_type: this.zxczxc_loan_detail.loan_type
        //       })
        //     }
        //   }
        let isvalid = false
        if(this.installment.type == 'PP'){
          if(this.installment.pay_per_term !== '' &&
              this.installment.day_term !== '' &&
              this.installment.start_date !== '' )
          {
            isvalid = true
          }
        }
        else if(this.installment.type == 'REGULAR'){
          if(this.installment.pay_per_term !== '' &&
            this.installment.term !== '' &&
            this.installment.day_term !== '' &&
            this.installment.start_date !== '' )
          {
            isvalid = true
          }
        }

        if(!isvalid)
          return false

        if(this.installment.terms){
          this.installment_terms = this.installment.terms
        }
        else{
          if(this.backup_installment_terms.length > 0){
            this.installment_terms = this.backup_installment_terms
          }
          else{
            this.installment_terms = []
          }

          if(this.installment.type !== 'PP'){
            for(let a = 0; a < parseInt(this.installment.term); a++){
              if(a == 0){
                this.installment_terms.push({
                  status: 'ONGOING',
                  due_date: moment(this.installment_start_date).format('YYYY-MM-DD'),
                  installment_amount: this.installment.pay_per_term,
                  late_charge: '',
                  discount: '',
                  paid_amount: '',
                  paid_date: '',
                  remaining_loan: '',
                  loan_type: this.installment.type
                })
                // parseFloat(this.repayment.term) * parseFloat(this.repayment.amount)
              }
              else{
                this.installment_terms.push({
                  status: 'ONGOING',
                  due_date: moment(this.installment_start_date).add(parseInt(this.installment.day_term) * a, 'days', true).format("YYYY-MM-DD"),
                  installment_amount: this.installment.pay_per_term,
                  late_charge: '',
                  discount: '',
                  paid_amount: '',
                  paid_date: '',
                  remaining_loan: '',
                  loan_type: this.installment.type
                })
              }
            }
          }
          else{
            let counter = 5

            for(let a = 0; a < counter; a++){
              if(a == 0){
                this.installment_terms.push({
                  status: 'ONGOING',
                  due_date: moment(this.installment_start_date).format('YYYY-MM-DD'),
                  installment_amount: this.installment.pay_per_term,
                  late_charge: '',
                  discount: '',
                  paid_amount: '',
                  paid_date: '',
                  remaining_loan: '',
                  loan_type: this.installment.type
                })
                // parseFloat(this.repayment.term) * parseFloat(this.repayment.amount)
              }
              else{
                this.installment_terms.push({
                  status: 'ONGOING',
                  due_date: moment(this.installment_start_date).add(parseInt(this.installment.day_term) * a, 'days', true).format("YYYY-MM-DD"),
                  installment_amount: this.installment.pay_per_term,
                  late_charge: '',
                  discount: '',
                  paid_amount: '',
                  paid_date: '',
                  remaining_loan: '',
                  loan_type: this.installment.type
                })
              }
            }
          }
          this.$forceUpdate()
          
        }
        this.installment.installment_json = JSON.stringify(this.installment_terms)
        this.highlightTable();
      },
      highlightTable(){
        let flag_used = false

        let hide_detail, hide_index
        this.hidden_term_detail = ''
        this.hidden_index = ''
        this.installment_terms.forEach(function(item,index){

          // if(item.remaining_loan !== ''){
          //   hide_detail = item
          //   hide_index = index
          // }

          item.flag_action_btn = false
          if(item.status == 'PAID'){
            item.className = 'border-success'
            item.classText = 'text-success'
            hide_detail = item
            hide_index = index
          }
          else{
            if(moment(item.due_date, "YYYY-MM-DD").isBefore(moment(new Date(), "YYYY-MM-DD"), 'day')){

              item.flag_action_btn = false
              if(!flag_used){
                item.flag_action_btn = true
                hide_detail = item
                hide_index = index
                flag_used = true
              }
              
              item.className = 'border-danger blink-danger'
              item.classText = 'text-danger blink-danger'
            }
            else if(moment(item.due_date, "YYYY-MM-DD").isSame(moment(new Date(), "YYYY-MM-DD"), 'day')){
              item.flag_action_btn = false
              if(!flag_used){
                item.flag_action_btn = true
                hide_detail = item
                hide_index = index
                flag_used = true
              }
              item.className = 'border-warning'
              item.classText = 'text-warning'
            }
            else{
              if(!flag_used){
                item.flag_action_btn = true
                hide_detail = item
                hide_index = index
                flag_used = true
              }
            }
          }
        })

        this.hidden_term_detail = hide_detail
        this.hidden_index = hide_index

        if (this.timeout) clearTimeout(this.timeout)
        this.timeout = setTimeout(() => {
          this.$emit('updateInstallment', this.installment)
        }, 3000)
      },
    }
  }
  </script>