<template>
    <div class="row">
      <div class="col-lg-12">
        <form>
          <div class="row">
            <div class="col-md-6">
              <b-form-group
                label="Customer Name / 顾客姓名"
                label-for="form-customer-name"
                class="mb-3"
              >
                <b-form-input
                  type="text"
                  id="form-customer-name"
                  v-model="customer.name"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                label="IC|Passport / 身份证|护照"
                label-for="form-customer-nric"
                class="mb-3"
              >
                <b-form-input
                  type="text"
                  id="form-customer-nric"
                  v-model="customer.nric"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-6" v-if="!tempRegister">
              <b-form-group
                label="D.O.B / 生日日期"
                label-for="form-customer-dob"
                class="mb-3"
              >
                <date-picker v-model="dob" @input="customer.dob = fixDate($event)" :first-day-of-week="1" lang="en" format="YYYY-MM-DD"></date-picker>
              </b-form-group>
            </div>
            <div class="col-md-6">
              <b-form-group
                label="Contact No / 电话号码"
                label-for="form-customer-contact"
                class="mb-3"
              >
                <b-form-input
                  id="form-customer-contact"
                  type="text"
                  v-model="customer.contact"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>    

          <div class="row" v-if="!tempRegister">
            <div class="col-md-9" >
              <b-form-group
                label="Address / 地址"
                label-for="form-customer-address"
                class="mb-3"
              >
                <b-form-input
                  id="form-customer-address"
                  type="text"
                  v-model="customer.address"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-3">
              <b-form-group
                label="Occupation / 工作"
                label-for="form-customer-working"
                class="mb-3"
              >
                <b-form-input
                  id="form-customer-working"
                  type="text"
                  v-model="customer.working"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>                                 
        </form>
      </div>
    </div>
</template>

<script>
  import DatePicker from "vue2-datepicker";
  import moment from 'moment';
  export default {
    name: 'CustomerBasic',
    components: { DatePicker },
    props: {
      customerId: {
        type: String,
        default: '0'
      },
      tempRegister: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        customer:{
          name: '',
          nric: '',
          dob: '',
          contact: '',
          address: '',
          working: ''
        },
        dob:'',
        fields: [
          {
            id: 1,
          },
        ],
      }
    },
    watch: {
      customer: {
        // This will let Vue know to look inside the array
        deep: true,

        // We have to move our method to a handler field
        handler(){
          // console.log('The list of colours has changed!');
          this.$emit('BasicInfoChanged', this.customer)
        }
      }
    },
    created(){
      this.loadCustomerInfo(this.customerId)
    },
    methods: {
      loadCustomerInfo(cus_id){
        let self = this
        self.loading = true
        const baseURI = 'customers/'+cus_id
      
        self.$http.get(baseURI)
        .then((result) => {
          if(result.data.status == 'success'){
            let customer_basic = result.data.data
            self.customer = {
              name: customer_basic.name,
              nric: customer_basic.idno,
              dob: customer_basic.dob,
              contact: customer_basic.contact,
              address: customer_basic.address,
              working: customer_basic.career
            }
            self.dob = moment(customer_basic.dob).toDate()
            self.loading = false
          }
        })
        // console.log('loadCustomerInfo(cus_id)', cus_id);
      },
      fixDate(event){
        return moment(event).format('YYYY-MM-DD');
      },
    }
  }
  </script>