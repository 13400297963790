<style scoped>
hr{
  color: currentColor
}

a.for-call{
  text-decoration: underline !important;
}

h5{
  font-size:calc(10px + 0.3vw);
}
</style>

<template>
  <div>
    <h5 class="mb-2">Contact/s
      <router-link :to="{ name: 'Edit Customer', params: { id: customerId } }" v-if="fromWhere == 'loan' && !lock_editBtn">
        <a style="float:right" class="btn btn-sm btn-success">
          <i class="uil uil-pen"></i>
        </a>
      </router-link>
      <a class="btn btn-sm btn-primary" v-if="fromWhere !== 'loan' && !lock_editBtn" @click="openModalContact">
        <i class="uil uil-plus"></i>
      </a>
    </h5>
    <div class="row" v-if="contact_lists.length > 0">
      <div class="col-lg-4 col-md-6" v-for="(field,index) in contact_lists" :key="index">
        <div class="card" style="border: 1px solid #f6f6f6">
          <div class="card-body">
            <div style="display:flex; justify-content:space-between">
              <h6>{{ field.name }} {{ field.relationship !== '' ? ' - '+field.relationship : '' }}
              </h6>
              <button :disabled="lock_editBtn" v-show="!lock_editBtn" class="btn btn-sm btn-outline-danger" style=" float:right" @click="deleteRow(index)" >
                <i class="uil uil-trash-alt"></i>
              </button>
            </div>
            
            <hr>
            <p><small>Contact/联络号码: <a class="for-call" v-if="field.contact !== '' " :href="'tel:'+field.contact">{{ field.contact }}</a> <span v-else>Empty/空</span> </small></p>
            <p><small>Address/地址: {{ field.address !== '' ? field.address : 'Empty/空' }}</small></p>
            <p><small>Remark/备注: {{ field.remark !== '' ? field.remark : 'Empty/空' }}</small></p>
          </div>
        </div>
      </div>
    </div>
    <div class="row" v-if="contact_lists.length == 0">
      <div>
        <h6>No dependant added. <span v-if="fromWhere == 'loan' && !lock_editBtn">click <router-link :to="{ name: 'Edit Customer', params: { id: customerId } }">
        here
      </router-link> to add dependant</span></h6>
      </div>
    </div>

    <b-modal
      id="modal-center-contact"
      centered
      title="Customer Contact"
      title-class="font-18"
      hide-footer
    >
      <b-form-group
        label="Contact Person / 联络人"
        label-for="customercontact-person"
        class="mb-3"
      >
        <b-form-input
          type="text"
          id="customercontact-person"
          v-model="modal_contact.name"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Relationship / 关系"
        label-for="customercontact-relationship"
        class="mb-3"
      >
        <select class="form-select" v-model="modal_contact.relationship">
          <option disabled>Select</option>
          <option value="SELF">Self / 借主</option>
          <option value="WIFE">Wife / 老婆</option>
          <option value="CHILDREN">Children / 孩子</option>
          <option value="SIBLING">Sibling / 兄弟姐妹</option>
          <option value="RELATIVES">Relatives / 亲戚</option>
        </select>
      </b-form-group>

      <b-form-group
        label="Contact No / 联络号码"
        label-for="customercontact-contact"
        class="mb-3"
      >
        <b-form-input
          type="text"
          id="customercontact-contact"
          v-model="modal_contact.contact"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Address / 地址"
        label-for="customercontact-address"
        class="mb-3"
      >
        <b-form-input
          type="text"
          id="customercontact-address"
          v-model="modal_contact.address"
        ></b-form-input>
      </b-form-group>

      <b-form-group
        label="Remark / 备注"
        label-for="customercontact-remark"
        class="mb-3"
      >
        <b-form-input
          type="text"
          id="customercontact-remark"
          v-model="modal_contact.remark"
        ></b-form-input>
      </b-form-group>

      <div class="modal-footer">
        <button type="button" class="btn btn-primary" @click="addContact" :disabled="lock_createBtn" v-show="!lock_createBtn">
          Save
        </button>
        <button
          type="button"
          class="btn btn-secondary close"
          @click="$bvModal.hide('modal-center-contact')"
        >
          Close
        </button>
      </div>
    </b-modal>
  </div>
</template>

<script>

  export default {
    name: 'CustomerContact',  
    components: { },
    props: {
      customerId: {
        type: String,
        default: '0'
      },
      isEdit: {
        type: Boolean,
        default: false
      },
      fromWhere: {
        type: String,
        default: ''
      }
    },
    data() {
      return {
        contact_lists: [
        ],

        modal_contact: {
          name: '',
          relationship: '',
          contact: '',
          address: '',
          remark: ''
        }
        // temp_edit_contact:{},
      }
    },
    watch: {
      contact_lists: {
        // This will let Vue know to look inside the array
        deep: true,

        // We have to move our method to a handler field
        handler(){
          // console.log('The list of colours has changed!');
          if(this.fromWhere == ''){
            this.$emit('updatedParent', this.contact_lists)
          }
          else{
            return false
          }
        }
      },
      customerId(){
        // console.log('watch customer id', this.customerId)
        this.loadCustomerInfo(this.customerId)
      }
    },
    computed:{
      compute_user(){
        return this.$store.state.authfack.user
      },
      lock_editBtn(){
        if(this.compute_user.permissions.includes('*') || this.compute_user.permissions.includes('032')){
          return false
        }
        else{
          return true
        }
      },
      lock_createBtn(){
        if(this.compute_user.permissions.includes('*') || this.compute_user.permissions.includes('031')){
          return false
        }
        else{
          return true
        }
      },
    },
    created(){
      if(this.customerId !== '0'){
        this.loadCustomerInfo(this.customerId)
      }
    },
    methods: {
      openModalContact(){
        this.modal_contact = {
          name: '',
          relationship: '',
          contact: '',
          address: '',
          remark: ''
        }
        this.$bvModal.show('modal-center-contact')
      },
      loadCustomerInfo(cus_id){
        let self = this
        const baseURI = 'customers/'+cus_id
      
        self.$http.get(baseURI)
        .then((result) => {
          if(result.data.status == 'success'){
            // console.log('customer_basic dependents',result.data.data.dependents)
            let customer_basic = result.data.data
            if(customer_basic.dependents !== ''){
              self.contact_lists = JSON.parse(customer_basic.dependents)
            }
            else{
              self.contact_lists = []
            }
          }
        })
      },
      /**
       * Delete the row
       */
      deleteRow(index) {
        if (confirm("Are you sure you want to delete this element?")){
          this.contact_lists.splice(index, 1);
          this.update('delete')
        }
      },
      addContact(){
        this.contact_lists.push(this.modal_contact)
        this.update('add')
      },
      update(event){
        let self = this
        const baseURI = 'customers/'+self.customerId
        let formData = new FormData();
        formData.append('_method', 'PUT' )
        formData.append('dependents', JSON.stringify(self.contact_lists) )
        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            self.loading = false
            self.$notify({
              group: 'app',
              title: `${event == 'delete' ? 'Delete' : 'Add'} contact successful`,
              type: 'success'
            });
            self.$bvModal.hide('modal-center-contact')
            self.loadCustomerInfo(self.customerId)
          }
        })
        .catch((err) => {
          if(err == 'session_timeout'){
            self.$notify({
              group: 'auth',
              title: 'User Authentication',
              text: 'Your session has expired, please login again',
              type: 'warn'
            });
            self.logout();
            self.$router.push({
              path: "/login",
            });
          }
        })
      }
      // editRow(index){
        
      //   this.temp_edit_contact = JSON.parse(JSON.stringify(this.contact_lists[index]));
      //   this.contact_lists[index].flag_edit = true
      // },
      // cancelEdit(index){
      //   console.log('cancelEdit',index, this.temp_edit_contact)

      //   this.contact_lists[index].name = this.temp_edit_contact.name
      //   this.contact_lists[index].relationship = this.temp_edit_contact.relationship
      //   this.contact_lists[index].contact_no = this.temp_edit_contact.contact_no
      //   this.contact_lists[index].address = this.temp_edit_contact.address
      //   // this.contact_lists[index].occupation = this.temp_edit_contact.occupation
      //   this.contact_lists[index].remark = this.temp_edit_contact.remark
      //   this.contact_lists[index].flag_edit = false

      // },
      // updateThis(index){
      //   console.log('updateThis \n')
      //   console.log('name', this.contact_lists[index].name)
      //   console.log('relationship', this.contact_lists[index].relationship)
      //   console.log('contact_no', this.contact_lists[index].contact_no)
      //   console.log('address', this.contact_lists[index].address)
      //   console.log('remark', this.contact_lists[index].remark)

      //   this.contact_lists[index].flag_edit = false
      // },
    }
  }
  </script>