<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
      <div class="row">
        <b-overlay :show="loading" rounded="sm">
        <div class="col-lg-12">
          <div class="card">
            <div class="card-body">
              <div class="mb-3 row">
                <div class="col-md-4">

                  <label>Customer / 顾客</label>

                  <multiselect v-model="selected_customer_id" label="displayed" track-by="uuid" placeholder="Type to search" open-direction="bottom" :options="lists" :multiple="false" :searchable="true" :loading="isLoading" :options-limit="300" :max-height="600" :show-no-results="true" :hide-selected="false" @search-change="asyncFind" deselect-label="Can't remove this value" :allow-empty="false">
                    <template slot="tag" slot-scope="{ option }"><span>{{ option.name }}</span></template>
                    <span slot="noResult">Oops! No result found.</span>
                  </multiselect>

                  <div class="card">
                    <div class="card-body">
                      <p><small>Contact/联络号码: <a class="for-call" v-if="selected_customer_id.contact !== '' " :href="'tel:'+selected_customer_id.contact">{{ selected_customer_id.contact }}</a> <span v-else>Empty/空</span> </small></p>
                      <p><small>IC/身份证: {{ selected_customer_id.idno !== '' ? selected_customer_id.idno : 'Empty/空' }}</small></p>
                      <p><small>D.O.B/生日日期: {{ selected_customer_id.dob !== '' ? selected_customer_id.dob : 'Empty/空' }}</small></p>
                      <p><small>Address/地址: {{ selected_customer_id.address !== '' ? selected_customer_id.address : 'Empty/空' }}</small></p>
                      <p><small>Job/职业: {{ selected_customer_id.career !== '' ? selected_customer_id.career : 'Empty/空' }}</small></p>
                      <p><small>Remark/备注: {{ selected_customer_id.remark !== '' ? selected_customer_id.remark : 'Empty/空' }}</small></p>
                    </div>
                  </div>
                 

                </div>
                <div class="col-md-8">
                  <Customer-contact-list v-show="selected_customer_id.uuid !== '0' " :customer-id="selected_customer_id.uuid" :is-edit="true" from-where="loan" />
                </div>
              </div>
            </div>
          </div>

          <div class="row">
            <div class="col-md-3">

              <div class="card">
                <div class="card-body" style="min-height:600px; overflow-y:auto">

                <h6 class="font-size-16 m-3">
                  <i class="uil uil-list-ul me-1"></i> Loans
                </h6>
                <ul class="list-unstyled chat-list">
                  <li
                    v-for="(item, index) in customer_loan_list"
                    :key="index"
                    @click="view_this_loan(item)"
                    :class="{ active: item.selected }"
                    >
                    <a href="javascript:void(0)">
                      <div class="media">

                        <div class="media-body me-2" >
                          <h6 class="side-list-style text-truncate mb-1">
                            {{ item.uuid !== '0' ? item.account_no : 'New Loan' }} <span v-if="item.uuid !== '0' && item.loan_status == 'COMPLETE'" class="badge bg-success font-size-10 ms-2">Complete</span>
                            <span v-else-if="item.uuid !== '0' && item.loan_status !== 'COMPLETE' " class="font-size-12 ms-1">(Active)</span>
                            <!-- {{ item.loan_status == 'COMPLETE' ? '('+item.loan_status+')' : '' }} -->
                          </h6>
                          <p class="side-list-style text-truncate mb-0">{{ item.uuid !== '0' ? 'Amount: '+item.loan_amount : 'Please fill in the loan details' }}</p>
                        </div>

                        <div class="flex-end overflow-hidden">
                          <div v-show="item.uuid !== '0' " class="side-list-style text-truncate">{{ item.loan_date !== '' ? moment(item.loan_date).format("YYYY-MM-DD") : 'Invalid date format' }}
                            <br>
                            <small>Last: {{ moment(item.created_at).format("YYYY-MM-DD HH:mm") }}</small>
                          </div>
                        </div>
                        
                      </div>
                    </a>
                  </li>
                </ul>
                </div>
              </div>
            </div>


            <div class="col-md-9 ">
              
              <Loan-component @refresh="load_customer_loan_list" :loan_id="current_loan_id" @updateLoanDetail="retrieveLoanInfo($event)"/>

              <Loan-installment :loan_id="current_loan_id" :loan_info="loan_info" @updateInstallment="retrieveInstallmentInfo($event)" @refresh_page="load_detail"/>

              <div class="custom-accordion">
                <b-card class="mt-4">
                  <h6 class="mb-2">
                    <a
                      href="javascript: void(0);"
                      class="text-dark"
                      data-toggle="collapse"
                      v-b-toggle.filtersizes-collapse
                    >
                    <i class="mdi mdi-chevron-up float-end accor-down-icon"></i>
                      Attachment Images
                    </a>
                    <a href="javascript:void(0)" class="btn btn-outline-primary btn-sm" v-show="picture_list.length !== dropzoneOptions.maxFiles && current_loan_id !== '0' && loan_info.loan_status !== 'COMPLETE' &&  compute_user.permissions.includes('*')" @click="trigger_event">
                        Add
                    </a> 
                  </h6>

                  <b-collapse visible id="filtersizes-collapse">
                    <div class="row">
                      <div v-for="(item,ind) in picture_list" :key="ind" class="col-md-3 mb-3">
                        <div style="position:relative">
                          <button v-show="loan_info.loan_status !== 'COMPLETE' && compute_user.permissions.includes('*')" style="position:absolute; top:0" @click="delete_image(item.id, ind)">Delete</button>
                          <img :alt="item.filename" :title="item.filename" style="max-height:301px; width:100%; cursor:pointer" :src="item.url" @click="view_image(item.url)">
                        </div>
                      </div>

                      <vue-dropzone
                        v-show="picture_list.length !== dropzoneOptions.maxFiles && loan_info.loan_status !== 'COMPLETE'"
                        :class="dropzoneClass"
                          id="dropzone"
                          ref="myVueDropzone"
                          :use-custom-slot="true"
                          :options="dropzoneOptions"
                          @vdropzone-sending="sendingEvent"
                          @vdropzone-complete="afterComplete"
                        >
                          <div class="dropzone-custom-content">
                            <i class="display-4 text-muted bx bxs-cloud-upload"></i>
                            <h4>Drop files here or click to upload.</h4>
                          </div>
                      </vue-dropzone>
                    </div>
                  </b-collapse>
                </b-card>
              </div>
  
              <div class="mt-4" v-show="current_loan_id == '0' ">
                <b-button @click="submit" variant="primary" :disabled="flag_submit">Submit</b-button>
              </div>
            </div>

          </div>

        </div>

        <b-modal
          id="modal-center-image"
          centered
          size="xl"
          title="View Image"
          title-class="font-18"
          hide-footer
          >
          <img style="max-width:100%" :src="viewed_image_url">

          <div class="modal-footer">
            <a href="javascript:void(0)" class="btn btn-danger" @click="close_modal_image">
              <i class="uil uil-times me-1"></i> Cancel
            </a>
          </div>
        </b-modal>

        <b-modal
          id="modal-quick-customer"
          title="Quick Customer Registration"
          title-class="font-18"
          hide-footer
          >
          <Customer-basic :customer-info="quick_basic_info" :temp-register="true" @BasicInfoChanged="onChildUpdate($event)" />

          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="createTemp">
              Save
            </button>
            <button
              type="button"
              class="btn btn-secondary close"
              @click="$bvModal.hide('modal-quick-customer')"
            >
              Close
            </button>
          </div>
        </b-modal>

        </b-overlay>
      </div>
  </Layout>
</template>

<style scoped>
  .app-search2{
    display: flex;
    padding-bottom: 15px;
    /*justify-content: space-between;*/
  }
  .app-search2 .form-control {
    border: 1px solid #ced4da;
    /*border: none;*/
    height: 38px;
    padding-left: 40px;
    padding-right: 20px;
    background-color: transparent;
    box-shadow: none;
    border-radius: 5px;
  }

  .app-search2 span{
    position: absolute;
    z-index: 10;
    font-size: 16px;
    line-height: 38px;
    left: 13px;
    top: 0;
  }

  .speed-register{
    margin-left: 15px;
    font-size: 0.9rem;
  }

  .speed-register:hover{
    text-decoration: underline !important;
  }

  .list-group-item.active{
    background-color: #4DBAEA;
  }

  .side-list-style{
    font-size:calc(10px + 0.2vw);
  }

  .dropzone .dz-preview .dz-image {
    width: 150px;
    height: 150px;
  }

  h5, h6, p, span, label, input, textarea{
    font-size:calc(10px + 0.5vw);
  }

  @media only screen and (max-width: 700px) {
    .side-list-style{
      font-size:calc(9px + 0.2vw);
    }
  }
  
</style>

<script>
  import Layout from "../../layouts/main";
  import PageHeader from "@/components/page-header";
  import appConfig from "@/app.config";

  import Multiselect from "vue-multiselect";
  import vue2Dropzone from "vue2-dropzone";
  import "vue2-dropzone/dist/vue2Dropzone.min.css";

  import moment from 'moment'

  import CustomerBasic from "../customer/CustomerBasic"
  import CustomerContactList from "../customer/CustomerContact"
  import { authFackMethods } from "@/state/helpers";


  import LoanComponent from './loan'
  import LoanInstallment from './loan_installment'

  import Swal from "sweetalert2"

  export default {
    name: 'LoanFullDetail',
    page: {
      title: "Loan Detail",
      meta: [
        {
          name: "description",
          content: appConfig.description,
        },
      ],
    },
    components: { Layout, PageHeader, Multiselect, CustomerBasic, CustomerContactList, vueDropzone: vue2Dropzone, LoanComponent, LoanInstallment },
    props: {
      isEdit: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        loading: false,
        selected_customer_id: {
          uuid: '0'
        },
        quick_basic_info: {
          name: '',
          nric: '',
          contact: ''
        },
        timeout: null,
        debouncedInput: '',
        // searched_list: [],

        //left side
        customer_loan_list: [],

        dropzoneOptions: {
          // url: "https://httpbin.org/post",
          url: process.env.VUE_APP_GLOBALURL+'/pictures',
          thumbnailWidth: 150,
          thumbnailHeight: 150,
          maxThumbnailFilesize: 1,
          maxFilesize: 11,
          maxFiles: 6,
          parallelUploads:1,
          autoDiscover: false,
          uploadMultiple:true,
          autoProcessQueue: false,
          // autoProcessQueue: true,
          acceptedFiles: ".jpeg,.jpg,.png,.gif",
          // forceFallback: true,
          headers: {
            'Cache-Control': null,
            'X-Requested-With': null,
            Authorization: "Bearer " + JSON.parse(localStorage.getItem("user")).token
          },
          addRemoveLinks: true
        },

        picture_list: [],
        queue_list: 0,

        current_loan_id: '0',
        new_loan_id: 0,

        //restructure loan variable
        title: "Loan Detail",
        items: [
          {
            text: "Loan",
            href: "/loan/list",
          },
          {
            text: "detail",
            active: true,
          },
        ],

        viewed_image_url: '',

        loan_info: {},
        installment_info: {},

        response_loan_detail: {},

        isLoading: false,

        lists: [],

        flag_submit: false,
      }
    },
    watch: {
      selected_customer_id(){
        if(this.selected_customer_id && this.selected_customer_id !== 'null' && this.selected_customer_id !== 'undefined'){
          this.load_customer_loan_list()
        }
      },
      '$route.params.id'(){
        if(this.$route.params.id == 0 || this.$route.params.id == '0'){
          this.reset_for_new_loan()
        }
        else{
          this.current_loan_id = this.$route.params.id
          this.load_detail()
        }
      }
    },
    computed: {
     searched_customer: {
        get() {
          return this.debouncedInput
        },
        set(val) {
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.debouncedInput = val
            // console.log('val', val)
            this.search_this_customer(val)
          }, 700)
        }
      },
      dropzoneClass(){
        let number = 3
        // if(this.picture_list.length == 0){
        //   number = 12
        // }
        // else if(this.picture_list.length == 1){
        //   number = 9
        // }
        // else if(this.picture_list.length == 2){
        //   number = 6
        // }
        // else if(this.picture_list.length == 3){
        //   number = 3
        // }
        // console.log(number, this.picture_list.length)
        return 'col-md-'+number
      },

      compute_user(){
        return this.$store.state.authfack.user
      },
      lock_viewBtn(){
        if(this.compute_user.permissions.includes('*') || this.compute_user.permissions.includes('042') || this.compute_user.permissions.includes('044')){
          return false
        }
        else{
          return true
        }
      },
    },
    created(){
      if(!this.lock_viewBtn){
        this.search_this_customer()
        this.current_loan_id = this.$route.params.id
        this.load_detail()
      }
      else{
        this.$router.push({
          name: 'error-page'
        });
      }
      
    },
    methods: {
      ...authFackMethods,
      asyncFind (query) {
        let self = this
        self.isLoading = true
        // console.log(query)
        const baseURI = 'customers/list'
        let formData = new FormData();
        formData.append('val', query);
        formData.append('status', 'ACTIVE,INACTIVE,DELETED');
        self.lists = []
        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){

            result.data.data.forEach(function(item){
              item.displayed = item.name+' '+item.contact+' ('+item.status+')'
            })

            self.lists = result.data.data
            self.isLoading = false
          }
        })
        .catch((err) => {
          if(err == 'session_timeout'){
            self.$notify({
              group: 'auth',
              title: 'User Authentication',
              text: 'Your session has expired, please login again',
              type: 'warn'
            });
            self.logout();
            self.$router.push({
              path: "/login",
            });
          }
        })
      },
      openModalQuick(){
        this.quick_basic_info = {
          name: '',
          nric: '',
          contact: ''
        }
        this.$bvModal.show('modal-quick-customer')
      },

      reset_for_new_loan(){
        // console.log('reset_for_new_loan')
        this.picture_list = []
        this.loan_info = {}
        this.installment_info = {}
        this.response_loan_detail = []
      },

      retrieveLoanInfo(loan_obj){
        // console.log(loan_obj)
        this.loan_info = JSON.parse(JSON.stringify(loan_obj));
      },
      retrieveInstallmentInfo(installment_obj){
        // console.log(installment_obj)
        this.installment_info = JSON.parse(JSON.stringify(installment_obj));
      },
      load_detail(){
        let self = this
        if(self.current_loan_id == '0'){
          self.customer_loan_list.unshift({
            uuid: '0',
            selected: true
          }); 
        }
        else{
          const baseURI = 'loans/'+self.current_loan_id
          self.response_loan_detail = {}
          self.$http.get(baseURI)
          .then((result) => {
            if(result.data.status == 'success'){
              // console.log('read loan detail',result.data.data)
              self.response_loan_detail = result.data.data
              self.response_loan_detail_loan_date = new Date(moment(self.response_loan_detail.loan_date, 'YYYY-MM-DD'))
              self.lists = []
              self.loadCustomerInfo(self.response_loan_detail.customer_uuid)
              self.load_image(self.current_loan_id)
            }
          })
          .catch((err) => {
            if(err == 'session_timeout'){
              self.$notify({
                group: 'auth',
                title: 'User Authentication',
                text: 'Your session has expired, please login again',
                type: 'warn'
              });
              self.logout();
              self.$router.push({
                path: "/login",
              });
            }
          })
        }
      },
      load_image(loan_id){
        let self = this
        const baseURI = 'pictures/list'
        let formData = new FormData();
        formData.append('loan_uuid', loan_id);
        self.picture_list = []
        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            // console.log('load_image',result.data.data)
            self.picture_list = result.data.data
          }
        })
      },
      loadCustomerInfo(customer_id){
        let self = this
        const baseURI = 'customers/'+customer_id
        
        self.$http.get(baseURI)
        .then((result) => {
          if(result.data.status == 'success'){
            // console.log('read customer detail',result.data.data)
            let customer_detail = result.data.data

            self.lists.push(customer_detail)

            self.lists[0].displayed = customer_detail.name+' '+customer_detail.contact+' ('+customer_detail.status+')'
            self.selected_customer_id = customer_detail
            // let a = ''
            // self.searched_list.forEach(function(item, index){
              
            //   if(item.status == customer_detail.status){
            //     a = index
            //   }
            // })
            // self.searched_list[a].list.push({
            //   uuid: customer_detail.uuid,
            //   name: customer_detail.name,
            //   idno: customer_detail.idno,
            //   contact: customer_detail.contact,
            //   status: customer_detail.status
            // })

            // //selected_customer_id changed will trigger loan_customer_loan_list()
            // self.selected_customer_id = {
            //   uuid: customer_detail.uuid, 
            //   name: customer_detail.name,
            //   idno: customer_detail.idno,
            //   contact: customer_detail.contact,
            //   status: customer_detail.status
            // }
          }
        })
      },
      load_customer_loan_list(){
        let self = this
        self.loading = true
        const baseURI = 'loans/list'
        let formData = new FormData();
        formData.append('customer_uuid', this.selected_customer_id.uuid);
        self.customer_loan_list = []
        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            // console.log('read customer loan list',result.data.data)
            self.customer_loan_list = result.data.data
            self.customer_loan_list.unshift({
              uuid: '0',
              selected: true
            });

            if(self.current_loan_id !== '0'){
              self.customer_loan_list.forEach(function(item){
                item.selected = false
                if(item.uuid == self.current_loan_id){
                  item.selected = true
                  self.flag_edit_loan = false
                  self.flag_edit_installment = false
                }
              })
            }  
            self.loading = false    
          }
        })
        .catch((err) => {
          if(err == 'session_timeout'){
            self.$notify({
              group: 'auth',
              title: 'User Authentication',
              text: 'Your session has expired, please login again',
              type: 'warn'
            });
            self.logout();
            self.$router.push({
              path: "/login",
            });
          }
        })
      },
      //create speed / quick register
      onChildUpdate (obj) {
        this.quick_basic_info = {
          name: obj.name,
          nric: obj.nric,
          contact: obj.contact
        }
      },
      createTemp(){
        let self = this
        const baseURI = 'customers'
        let formData = new FormData();

        formData.append('name', self.quick_basic_info.name )
        formData.append('idno', self.quick_basic_info.nric )
        formData.append('contact', self.quick_basic_info.contact )
        formData.append('remark', 'Quick register on '+moment().format('YYYY-MM-DD HH:mm') )
        formData.append('status', 'ACTIVE')
        
        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            self.$notify({
              group: 'app',
              title: 'Quick register successful',
              type: 'success'
            });
            self.$bvModal.hide('modal-quick-customer')

            // self.searched_list[1].list.push({
            //   uuid: result.data.data.id,
            //   name: self.quick_basic_info.name,
            //   idno: self.quick_basic_info.nric,
            //   contact: self.quick_basic_info.contact,
            //   status: 'ACTIVE'
            // })

            // self.selected_customer_id = {
            //   uuid: result.data.data.id,
            //   name: self.quick_basic_info.name,
            //   idno: self.quick_basic_info.nric,
            //   contact: self.quick_basic_info.contact,
            //   status: 'ACTIVE'
            // }
          }
        })
        .catch((err) => {
          if(err == 'session_timeout'){
            self.$notify({
              group: 'auth',
              title: 'User Authentication',
              text: 'Your session has expired, please login again',
              type: 'warn'
            });
            self.logout();
            self.$router.push({
              path: "/login",
            });
          }
        })
      },
      search_this_customer(){
        let self = this
        self.loading = true
        const baseURI = 'customers/list'
        let formData = new FormData();
        formData.append('val', '');
        formData.append('status', 'ACTIVE,INACTIVE,DELETED');
        // self.searched_list = []
        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){

            let active_list = [], inactive_list = [], deleted_list = []

            
            result.data.data.forEach(function(item){
              item.displayed = item.name+' '+item.idno+' '+item.contact
              if(item.status == "ACTIVE"){
                active_list.push(item)
              }
              else if(item.status == "INACTIVE"){
                inactive_list.push(item)
              }
              else{
                deleted_list.push(item)
              }
            })

            // self.searched_list.push({
            //   status: 'Quick Register',
            //   list: [
            //     {displayed: 'New'}
            //   ]
            // })

            // if(active_list.length > 0){
            //   self.searched_list.push({
            //     status: 'Active',
            //     list: active_list
            //   })
            // }
            // if(inactive_list.length > 0){
            //   self.searched_list.push({
            //     status: 'Inactive',
            //     list: inactive_list
            //   })
            // }
            // if(deleted_list.length > 0){
            //   self.searched_list.push({
            //     status: 'Deleted',
            //     list: deleted_list
            //   })
            // }

            self.loading = false
          }
        })
        .catch((err) => {
          if(err == 'session_timeout'){
            self.$notify({
              group: 'auth',
              title: 'User Authentication',
              text: 'Your session has expired, please login again',
              type: 'warn'
            });
            self.logout();
            self.$router.push({
              path: "/login",
            });
          }
        })
      },
      //image function
      view_image(image_url){
        let self = this
        this.viewed_image_url = image_url
        setTimeout(function(){
          self.$bvModal.show('modal-center-image')
        },250)
      },
      close_modal_image(){
        this.$bvModal.hide('modal-center-image')
        this.viewed_image_url = ''
      },
      delete_image(img_id,index){
        let self = this
        var a = confirm('Delete this?')
        if(a){
          const baseURI = 'pictures/'+img_id
          self.$http.delete(baseURI)
          .then((result) => {
            if(result.data.status == 'success'){
              self.$notify({
                group: 'app',
                title: 'Image deleted!',
                // text: 'Your session has expired, please login again',
                type: 'success'
              });
              self.picture_list.splice(index, 1)
            }
          })
        }
      },
      
      view_this_loan(obj){
        this.customer_loan_list.forEach(function(item){
          item.selected = false
        })
        obj.selected = true;
        // console.log("change loan ",obj,obj.uuid)

        if(obj.uuid !== this.current_loan_id){
          this.$router.replace({ name: 'Loan Detail', params: { id: obj.uuid }});
          this.current_loan_id = obj.uuid
          if(this.current_loan_id == '0'){
            this.resetAll()
          }
          else{
            this.load_image(this.current_loan_id)
          }
        }
        
      },
      resetAll(){
        this.picture_list = []
        this.queue_list = 0
        this.loan_info = {}
        this.installment_info = {}
        this.response_loan_detail = {}
      },


      fixDate(event){
        if(event){
          return moment(event).format('YYYY-MM-DD');
        }
        else{
          return ''
        }
      },

      //for vue dropzone
        removeAllFiles(){
          this.$refs.myVueDropzone.removeAllFiles();
        },
        // thumbnail(file, dataUrl){
        //   console.log(file, dataUrl)
        // },
        // exceeded(file){
        //   if(file.status == 'error' && !file.accepted){
        //     this.$notify({
        //       group: 'auth',
        //       title: 'Exceed file limit',
        //       text: 'Only 4 files/attachment are allowed',
        //       type: 'primary'
        //     });
        //   }
        // },
        trigger_event(){
          this.queue_list = this.$refs.myVueDropzone.getQueuedFiles().length
          if(this.queue_list > 0){
            this.loading = true
            this.$refs.myVueDropzone.processQueue();
          }
          else{
            this.loading = false
            let uuid = ''
            if(this.current_loan_id == '0' ){
              uuid = this.new_loan_id
            }
            else{
              uuid = this.current_loan_id
            }
            this.current_loan_id = uuid
            this.load_customer_loan_list()
          }
        },
        sendingEvent(file, xhr, formData) {
          // console.log(file, xhr,formData);
          let uuid = ''
          if(this.current_loan_id == '0' ){
            uuid = this.new_loan_id
          }
          else{
            uuid = this.current_loan_id
          }
          formData.append("loan_uuid", uuid);
          formData.append("loan_picture", file);
          // console.log(formData);
        },
        afterComplete() {
          // console.log('afterComplete')
          let self = this
          this.queue_list = this.queue_list - 1
          if(this.queue_list == 0){
            self.loading = false
            setTimeout(function(){
               self.$refs.myVueDropzone.removeAllFiles();
               self.flag_submit = false
               self.picture_list = []
               let uuid = ''
                if(self.current_loan_id == '0' ){
                  uuid = self.new_loan_id
                  self.current_loan_id = uuid
                  self.load_customer_loan_list()
                  self.load_image(uuid)
                  // self.$router.push({
                  //   path: `/loan/${uuid}`,
                  // });
                }
                else{
                  if(self.current_loan_id !== '0' ){
                    self.load_image(self.current_loan_id)
                  }
                  else{
                    self.load_image(self.current_loan_i)
                  }
                }
               //  self.$router.push({
               //    path: `/loan/${uuid}`,
               //  });
               // 
            },150)
          }
          
        },
      //end for vue dropzone
      submit(){
        if(this.selected_customer_id.status == 'ACTIVE' && this.selected_customer_id.uuid !== '0'){
          //step 1
          this.step1_createloan()
        }
        else{
          alert('Please select customer for this account')
        }
      },
      step1_createloan(){
        let self = this

        if(parseFloat(this.loan_info.loan_settlement || 0 ) == 0){
          alert('Settlement field cannot be empty')
          return false
        }
        self.flag_submit = true
        self.loading = true
        const baseURI = 'loans'
        let user = JSON.parse(localStorage.getItem('user'));
        let formData = new FormData();
        formData.append('customer_uuid', this.selected_customer_id.uuid);
        formData.append('user_uuid', user.id);
        formData.append('account_no', this.loan_info.account_no);
        formData.append('loan_date', this.loan_info.loan_date);
        formData.append('payment_method', this.loan_info.payment_method);
        formData.append('bank_account', this.loan_info.bank_account);
        formData.append('loan_status', this.loan_info.loan_status);
        formData.append('loan_collector', this.loan_info.loan_collector);
        formData.append('site_visit', this.loan_info.site_visit);
        formData.append('loan_amount', this.loan_info.loan_amount);
        formData.append('loan_deposit', this.loan_info.loan_deposit);
        formData.append('loan_process_fee', this.loan_info.loan_process_fee);
        formData.append('loan_capital', this.loan_info.loan_capital);
        formData.append('loan_settlement', this.loan_info.loan_settlement);        
        formData.append('remark', this.loan_info.remark);

        self.$http.post(baseURI,formData)
        .then((result) => {
          if(result.data.status == 'success'){
            self.new_loan_id = result.data.data.id
            let loan_id = result.data.data.id
            self.loading = false
            let terms = JSON.parse(self.installment_info.installment_json)
            if(terms.length > 0){
              //step 2
              self.step2_createinstallment(loan_id)
            }
          }
        })
        .catch((err) => {
          if(err == 'session_timeout'){
            self.$notify({
              group: 'auth',
              title: 'User Authentication',
              text: 'Your session has expired, please login again',
              type: 'warn'
            });
            self.logout();
            self.$router.push({
              path: "/login",
            });
          }
        })
      },
      step2_createinstallment(loan_id){
        let self = this
        //step 2
          self.loading = true
          const baseURI = 'installments'
          let formData = new FormData();
          formData.append('pay_per_term', self.installment_info.pay_per_term);
          formData.append('term', self.installment_info.term);
          formData.append('day_term', self.installment_info.day_term);
          formData.append('start_date', self.installment_info.start_date);
          formData.append('balance', self.installment_info.balance);
          formData.append('type', self.installment_info.type);
          formData.append('status', self.installment_info.status);
          formData.append('installment_json', self.installment_info.installment_json);
          formData.append('loan_uuid', loan_id);

          self.$http.post(baseURI,formData)
          .then((result) => {
            if(result.data.status == 'success'){
              self.loading = false
              self.flag_submit = false
              Swal.fire("Loan Application", "New loan account created", "success");

              // self.$notify({
              //   group: 'app',
              //   title: 'Loan Application',
              //   text: 'New loan account created',
              //   type: 'success'
              // });

              self.step3_createAttachment()
              // self.queue_list = self.$refs.myVueDropzone.getQueuedFiles().length
              // if(self.queue_list.length > 0){
                
              // }
              // else{
              //   self.current_loan_id = loan_id
              //   self.load_customer_loan_list()
              //  // self.loading = false
              // }
            }
          })
          .catch((err) => {
            if(err == 'session_timeout'){
              self.$notify({
                group: 'auth',
                title: 'User Authentication',
                text: 'Your session has expired, please login again',
                type: 'warn'
              });
              self.logout();
              self.$router.push({
                path: "/login",
              });
            }
          })
      },
      step3_createAttachment(){
        this.trigger_event()

        /*let self = this
        // this.tobeupload_photo.forEach(function(item){

        
        //step 3
          const baseURI = 'pictures'

          let formData = new FormData();
          formData.append('loan_picture', self.tobeupload_photo[0]);
          formData.append('loan_uuid', "400015dc02b742b58ad0c0f6f0ee9492");

          console.log(formData, loan_id)

          self.$http.post(baseURI,formData)
          .then((result) => {
            if(result.data.status == 'success'){
              // self.step3_createAttachment(loan_id)
               self.loading = false
            }
          })
          .catch((err) => {
            if(err == 'session_timeout'){
              self.$notify({
                group: 'auth',
                title: 'User Authentication',
                text: 'Your session has expired, please login again',
                type: 'warn'
              });
              self.logout();
              self.$router.push({
                path: "/login",
              });
            }
          })*/
        // })
      }
    }
  }
  </script>