<style scoped>
  
</style>

<template>
  <div>
    <div class="custom-accordion">
      <b-card>
        <h6 class="mb-2">
          <a
            href="javascript: void(0);"
            class="text-dark"
            data-toggle="collapse"
            v-b-toggle.loan_detail_collapse>
            <i class="mdi mdi-chevron-up float-end accor-down-icon"></i>Loan Detail <span v-show="loan_info.loan_status == 'COMPLETE' " class="text-success"><b>清数</b></span>
          </a>
          <div class="float-end me-1" v-show="loan_id !== '0' && loan_info.loan_status !== 'COMPLETE' && compute_user.permissions.includes('*')">
            <a class="btn btn-secondary btn-sm me-1" href="javascript:void(0)" v-show="!flag_edit_loan && loan_id !== '0' " @click="flag_edit_loan = true">
              Edit
            </a>
            <a class="btn btn-danger btn-sm" href="javascript:void(0)" v-show="!flag_edit_loan && loan_id !== '0' " @click="cancelLoan">
              Cancel
            </a>
            <a class="btn btn-info btn-sm me-1" href="javascript:void(0)" v-show="flag_edit_loan && loan_id !== '0' " @click="save">
              Save
            </a>
            <a class="btn btn-outline-warning btn-sm" href="javascript:void(0)" v-show="flag_edit_loan && loan_id !== '0' " @click="flag_edit_loan = false">
              Back
            </a>
          </div>
        </h6>
        <b-collapse id="loan_detail_collapse" visible class="mt-1">
      
          <div class="row" style="width:100%">
            <div class="col-md-4">
              <b-form-group
                label="Account No / 卡号"
                label-for="form-account"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  type="text"
                  id="form-account"
                  v-model="loan_info.account_no"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Loan Date / 贷款日期"
                label-for="form-loan-date"
                class="mb-3"
              >
                <date-picker :disabled="!flag_edit_loan && loan_id !== '0' " id="form-loan-date" v-model="loan_info_loan_date" @input="loan_info.loan_date = fixDate($event)" :first-day-of-week="1" lang="en" format="YYYY-MM-DD"></date-picker>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Status / 状态"
                label-for="form-loan-status"
                class="mb-3"
              >
                <b-form-input
                  readonly
                  id="form-loan-status"
                  type="text"
                  v-model="loan_info.loan_status"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <b-form-group
                label="Collector / 收数人"
                label-for="form-loan-collector"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  id="form-loan-collector"
                  type="text"
                  v-model="loan_info.loan_collector"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Site Visit / 看屋子"
                label-for="form-loan-site-visit"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  id="form-loan-site-visit"
                  type="text"
                  v-model="loan_info.site_visit"
                ></b-form-input>
              </b-form-group>
            </div>   
          </div>

          <div class="row">
            <div class="col-md-4">
              <b-form-group
                label="Payment Method / 付款方式"
                label-for="form-loan-payor1"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  id="form-loan-payor1"
                  type="text"
                  v-model="loan_info.payment_method"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Bank Account / 银行户口"
                label-for="form-loan-site-visit1"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  id="form-loan-site-visit1"
                  type="text"
                  v-model="loan_info.bank_account"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-12">
              <b-form-group
                label="Remark / 备注"
                label-for="form-loan-remark"
                class="mb-3"
              >
                <textarea
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  class="form-control"
                  id="form-loan-remark"
                  v-model="loan_info.remark"
                  rows="3"
                ></textarea>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <b-form-group
                label="Loan / 贷款"
                label-for="form-loan-amount"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  id="form-loan-amount"
                  type="number"
                  v-model="loan_info.loan_amount"
                  @change="autoCalculate('capital')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Deposit / 定金"
                label-for="form-loan-deposit"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  id="form-loan-deposit"
                  type="number"
                  v-model="loan_info.loan_deposit"
                  @change="autoCalculate('capital')"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Process Fee / 手续费"
                label-for="form-loan-process-fee"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  id="form-loan-process-fee"
                  type="number"
                  v-model="loan_info.loan_process_fee"
                  @change="autoCalculate('capital')"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>

          <div class="row">
            <div class="col-md-4">
              <b-form-group
                label="Capital / 支出"
                label-for="form-loan-capital"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  id="form-loan-capital"
                  type="number"
                  v-model="loan_info.loan_capital"
                ></b-form-input>
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                label="Settlement / 结算贷款"
                label-for="form-loan-settlement"
                class="mb-3"
              >
                <b-form-input
                  :disabled="!flag_edit_loan && loan_id !== '0' "
                  id="form-loan-settlement"
                  type="number"
                  v-model="loan_info.loan_settlement"
                ></b-form-input>
              </b-form-group>
            </div>
          </div>
      
        </b-collapse>
      </b-card>
    </div>
  </div>
</template>

<script>
  import DatePicker from "vue2-datepicker";
  import moment from 'moment'
  import Swal from "sweetalert2"
  export default {
    name: 'loanDetail',  
    components: { DatePicker },
    props: {
      loan_id: {
        type: String,
        default: '0'
      },
      // isEdit: {
      //   type: Boolean,
      //   default: false
      // },
      // fromWhere: {
      //   type: String,
      //   default: ''
      // }
    },
    data() {
      return {
        flag_edit_loan: false,
        backup_loan_info: {},
        loan_info: {
          uuid: '',
          customer_uuid: '',
          user_uuid: '',
          account_no: '',
          site_visit: '',
          loan_date: '',
          payment_method: '',
          bank_account: '',
          loan_status: 'ACTIVE',
          loan_collector: '',
          loan_amount: '0',
          loan_process_fee: '0',
          loan_capital: '0',
          loan_deposit: '0',
          loan_settlement: '0',
          remark: ''
        },
        loan_info_loan_date: '',
        timeout: null,
      }
    },
    computed: {
      compute_user(){
        return this.$store.state.authfack.user
      }
    },
    watch: {
      flag_edit_loan(){
        if(this.flag_edit_loan){
          this.backup_loan_info = JSON.parse(JSON.stringify(this.loan_info));
        }
        else{
          this.loan_info = this.backup_loan_info
          this.backup_loan_info = {}
        }
      },
      loan_info: {
        // This will let Vue know to look inside the array
        deep: true,

        // We have to move our method to a handler field
        handler(){
          if (this.timeout) clearTimeout(this.timeout)
          this.timeout = setTimeout(() => {
            this.$emit('updateLoanDetail', this.loan_info)
          }, 3000)
          
        }
      },
      loan_id(){
        // console.log('from loan vue',this.loan_id)
        if(this.loan_id == '0' || this.loan_id == 0){
          this.resetAll()
        }
        else{
          this.load_detail()
        }
      }
    },
    mounted(){
      this.$root.$on('refresh', () => {
        this.load_detail()
      });
      if(this.loan_id !== '0' || this.loan_id !== 0){
        this.load_detail()
      }
    },
    methods: {
      resetAll(){
        this.flag_edit_loan = false
        this.backup_loan_info = {}
        this.loan_info = {
          uuid: '',
          customer_uuid: '',
          user_uuid: '',
          account_no: '',
          site_visit: '',
          loan_date: '',
          payment_method: '',
          bank_account: '',
          loan_status: 'ACTIVE',
          loan_collector: '',
          loan_amount: '0',
          loan_process_fee: '0',
          loan_capital: '0',
          loan_deposit: '0',
          loan_settlement: '0',
          remark: ''
        }
        this.loan_info_loan_date = ''
        this.timeout = null
      },
      fixDate(event){
        if(event){
          return moment(event).format('YYYY-MM-DD');
        }
        else{
          return ''
        }
      },
      autoCalculate(target){
        if(target == 'capital'){
          if(this.loan_info.loan_amount !== '' && this.loan_info.loan_process_fee !== '' && this.loan_info.loan_deposit !== ''){
            
            let initial = parseFloat(this.loan_info.loan_amount)
            let fee = parseFloat(this.loan_info.loan_process_fee)
            let deposit = parseFloat(this.loan_info.loan_deposit)

            if((initial - fee - deposit) < 0 ){
              //capital negative value
              // this.flag_invalid = true
              this.$notify({
                group: 'app',
                title: 'Invalid loan',
                text: 'Your capital should be above 0',
                type: 'warn'
              });

              return false
            }
            else{
              // this.flag_invalid = false
              this.loan_info.loan_capital = (initial - fee - deposit).toFixed(2)
            }
          }
        }
      },
      load_detail(){
        let self = this
        let baseURI = `loans/${self.loan_id}`;
        self.$http.get(baseURI)
          .then((result) => {
          if(result.data.status == 'success'){
            // console.log(result.data, 'loan return data')
            self.loan_info = result.data.data
            self.loan_info_loan_date = new Date(moment(self.loan_info.loan_date, 'YYYY-MM-DD'))
            // self.$emit('updateLoanDetail', self.loan_info)
          }
        })
      },
      save(){
        let self = this

        let baseURI = `loans/${self.loan_id}`;
        let formData = new FormData();
        formData.append('_method', 'PUT' )
        formData.append('account_no', self.loan_info.account_no);
        formData.append('loan_date', self.loan_info.loan_date);
        formData.append('payment_method', self.loan_info.payment_method);
        formData.append('bank_account', self.loan_info.bank_account);
        formData.append('loan_status', self.loan_info.loan_status);
        formData.append('loan_type', self.loan_info.loan_type);
        formData.append('loan_collector', self.loan_info.loan_collector);
        formData.append('site_visit', self.loan_info.site_visit);
        formData.append('loan_amount', self.loan_info.loan_amount);
        formData.append('loan_process_fee', self.loan_info.loan_process_fee);
        formData.append('loan_capital', self.loan_info.loan_capital);
        formData.append('remark', self.loan_info.remark);
 
        self.$http.post(baseURI,formData)
          .then((result) => {
          if(result.data.status == 'success'){
            self.$notify({
              group: 'app',
              title: `Loan detail updated!`,
              type: 'success'
            });

            self.backup_loan_info = JSON.parse(JSON.stringify(self.loan_info));
            self.flag_edit_loan = false

            self.$emit('refresh')
          }
        })
      },
      cancelLoan(){
        let self = this
        Swal.fire({
          title: "Are you sure?",
          text: "Delete this loan",
          icon: "warning",
          showCancelButton: true,
          confirmButtonColor: "#34c38f",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes",
        }).then((result) => {
          if (result.value) {
            const baseURI = 'loans/'+self.loan_id
            self.$http.delete(baseURI)
            .then((result) => {
              if(result.data.status == 'success'){
                Swal.fire("Deleted!", "Loan has been deleted.", "success");
                self.$emit('refresh')
                // self.$notify({
                //   group: 'app',
                //   title: 'Loan deleted',
                //   // text: 'Your session has expired, please login again',
                //   type: 'success'
                // });

                // self.load_customer_loan_list()

                // self.resetLoanDetail()
                // self.view_list_detail = {
                //   uuid: '0'
                // }
              }
            })
            
          }
        });
        /*var a = confirm('delete this?')
        if(a){
          let self = this
          const baseURI = 'loans/'+this.loan_id
          self.$http.delete(baseURI)
          .then((result) => {
            if(result.data.status == 'success'){
              self.$notify({
                group: 'app',
                title: 'Loan deleted',
                // text: 'Your session has expired, please login again',
                type: 'success'
              });

              // self.load_customer_loan_list()

              // self.resetLoanDetail()
              // self.view_list_detail = {
              //   uuid: '0'
              // }
            }
          })
        } */     
      },
      loadCustomerInfo(cus_id){
        let self = this
        const baseURI = 'customers/'+cus_id
      
        self.$http.get(baseURI)
        .then((result) => {
          if(result.data.status == 'success'){
            // console.log('customer_basic dependents',result.data.data.dependents)
            let customer_basic = result.data.data
            if(customer_basic.dependents !== ''){
              self.contact_lists = JSON.parse(customer_basic.dependents)
            }
            else{
              self.contact_lists = []
            }
          }
        })
      }
    }
  }
  </script>