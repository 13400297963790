import moment from 'moment'
export default {
	formatNumber: function(value){
		return parseFloat(value || 0).toFixed(2)
	},
	formatDate: function(value){
		if(value == '')
			return ''
		else
			return moment(value).format('YYYY-MM-DD')
	}
}